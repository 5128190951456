import React from 'react';
import { Link, Location } from '@reach/router';

import { mergeClasses } from 'utilities/functions.tsx';

export default ({ activeClass, children, className, ...rest }) => (
  <Location>
    {({ location }) => (
      <Link
        {...rest}
        className={mergeClasses(className, (location.pathname.indexOf(rest.to) > -1 ? (activeClass || 'active') : null))}
      >
        {children}
      </Link>
    )}
  </Location>
)