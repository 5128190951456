import React from 'react';

import Paper from '@material-ui/core/Paper';

import styles from './PageWrapper.module.scss';

export default ({ children }) => (
  <Paper className={styles.wrapper}>
    {children}
  </Paper>
)