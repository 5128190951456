import React from 'react';

import { Link } from '@reach/router';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Switch from 'appComponents/Switch';
import TrashCan from './TrashCan';

import styles from './Schedule.module.css';

import { sendApiRequest } from 'utilities/api';
import { useFirebase } from 'utilities/hooks';

export default ({ schedule, setCrons }) => {
  const { user } = useFirebase();

  async function handleEnable(id) {
    const response = await sendApiRequest(
      '/users/crons/' + id + '/enable',
      user,
      'put'
    );

    if(response.status !== 'success') {
      throw new Error('Something went wrong. Please try your request again.');
    }

    setCrons(crons => crons.map(cron => {
      if(cron.id === id) {
        return {
          ...cron,
          status: 0
        };
      }
      return cron;
    }))
    return 'Your scheduled job was successfully enabled!';
  }

  async function handleDisable(id) {
    const response = await sendApiRequest(
      '/users/crons/' + id + '/disable',
      user,
      'put'
    );

    if(response.status !== 'success') {
      throw new Error('Something went wrong. Please try your request again.');
    }

    setCrons(crons => crons.map(cron => {
      if(cron.id === id) {
        return {
          ...cron,
          status: 1
        };
      }
      return cron;
    }))
    return 'Your scheduled job was successfully disabled!';
  }

  function removeJob(id) {
    setCrons(crons => crons.filter(cron => cron.id !== id));
  }

  return (
    <>
      {schedule.length < 5 && (
        <Link to="./schedule/new">
          <Button variant="contained" color="primary">Add New Job</Button>
        </Link>
      )}
      {schedule.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule.map(cron => (
              <TableRow key={cron.id} hover>
                <TableCell>{cron.name}</TableCell>
                <TableCell>
                  <Switch
                    enabled={cron.status === 0}
                    handleEnable={() => handleEnable(cron.id)}
                    handleDisable={() => handleDisable(cron.id)}
                  />
                </TableCell>
                <TableCell>
                  <TrashCan
                    id={cron.id}
                    className={styles.hover}
                    removeJob={removeJob}
                  />
                  <Link to={'/app/email-marketing/schedule/' + cron.id}>
                    <EditIcon
                      fontSize="large"
                      className={styles.hover}
                    />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}