import React from 'react';

import { Formik, Form } from 'formik';
import { object, boolean } from 'yup';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

const validationSchema = object().shape({
  terms: boolean().test(val => val === true)
});

const initialValues = {
  terms: true
}

function calculateBillingDate(type) {
  const date = new Date();
  let calculated;

  if(type === 'yearly') {
    calculated = addYear(date);
  } else {
    calculated = addMonth(date);
  }

  return calculated.toLocaleDateString();
}

function addMonth(date) {
  const d = date.getDate();
  date.setMonth(date.getMonth() + 1);
  if(date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

function addYear(date) {
  return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
}

export default ({ customer, selectedPlan, setSubscription }) => {
  const { user } = useFirebase();
  const { completeAction } = useSnackbar();

  async function handleSubmit(_, actions) {
    await completeAction(async() => {
      const response = await sendApiRequest('/users/billing/subscriptions', user, 'post', {
        token: customer.paymentMethods[0].token,
        planId: selectedPlan
      });

      if(!response.hasOwnProperty('subscription')) throw new Error('Something went wrong creating your subscription.');

      setSubscription(response.subscription);

      return 'You have successfully subscribed!';
    });

    actions.setSubmitting(false);
  }

  const method = customer.paymentMethods[0];

  return (
    <>
      <p>You have selected the {selectedPlan} plan.</p>
      <p>Your {method.imageUrl.indexOf('paypal') >= 0 ? (
        <>PayPal account</>
      ) : (
        <>card ending in {method.last4}</>
      )} will be charged ${selectedPlan === 'yearly' ? '180' : '20'}.00.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ errors, isSubmitting, touched }) => (
          <Form>
            <FormGroup row>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={true}
                    readOnly
                  />
                )}
                label={'You have selected auto pay and agreed to the terms of use. The next billing date is ' + calculateBillingDate(selectedPlan) + '.'}
              />
            </FormGroup>
            {isSubmitting ? (
              <CircularProgress/>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Confirm
              </Button>
            )}
          </Form>
        )}
      />
    </>
  );
}