import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import EnhancedField from 'siteComponents/EnhancedField';

import styles from './NewContactForm.module.css';
import inputs from '../form-inputs';

import { SnackbarContext } from 'contexts/SnackbarContext';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: ''
};

const validationSchema = object().shape({
  firstName: string(),
  lastName: string(),
  email: string()
    .required('Please enter an email address.')
    .email('Please enter a valid email address.')
});

export default ({ db, userId }) => {
  const { openSnackbar } = useContext(SnackbarContext);

  async function handleSubmit(data, actions) {
    try {

      const timestamp = Date.now();

      await db
        .collection('users')
        .doc(userId)
        .collection('contacts')
        .add({
          ...data,
          active: true,
          createdAt: timestamp,
          updatedAt: timestamp
        });

      actions.resetForm();
      openSnackbar('Contact successfully added!', 'success');

    } catch(e) {

      openSnackbar(e.message, 'error');

    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => (
        <Form className={styles.container}>
          {inputs.map(input => (
            <EnhancedField
              {...input}
              key={input.name}
              fullWidth={false}
              styles={{ marginRight: '15px' }}
            />
          ))}
          {isSubmitting ? (
            <CircularProgress/>
          ) : (
            <Button variant="contained" color="primary" type="submit">Add</Button>
          )}
        </Form>
      )}
    />
  )
}