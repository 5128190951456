import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

export default ({ closeModal, subscription, setSubscription }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { completeAction } = useSnackbar();
  const { user } = useFirebase();

  async function handleDowngrade() {
    setIsSubmitting(true);

    await completeAction(async() => {
      const response = await sendApiRequest(
        '/users/billing/subscriptions/' + subscription.id + '/downgrade',
        user,
        'put',
        {
          token: subscription.paymentMethodToken
        }
      );

      if(response.result !== 'success') throw new Error('Something went wrong. Please try again.');

      closeModal();
      setSubscription({
        ...subscription,
        changeDate: response.changeDate
      });
      return 'Your subscription will be changed on your next billing date.'
    });

    setIsSubmitting(false);
  }

  async function handleCancel() {
    setIsSubmitting(true);

    await completeAction(async() => {
      const response = await sendApiRequest(
        '/users/billing/subscriptions/' + subscription.id,
        user,
        'delete',
        { cronId: subscription.cronId || null }
      );

      if(response.result !== 'success') throw new Error('Something went wrong. Please try again.');

      setSubscription({
        ...subscription,
        ...response.subscription
      });
      closeModal();
      return 'Your subscription has been successfully cancelled.';
    });

    setIsSubmitting(false);
  }

  const buttonBlock = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCancel}
      >
        Yes, cancel my subscription
      </Button>{' '}
      {subscription.planId === 'yearly' && typeof subscription.cronId === 'undefined' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleDowngrade}
        >
          No, change to monthly
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={closeModal}
        >
          No, keep my subscription
        </Button>
      )}
    </>
  );

  return (
    <>
      <p>Are you sure?</p>
      {isSubmitting ? (<CircularProgress/>) : buttonBlock}
    </>
  );
}