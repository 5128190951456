import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';
import Typography from '@material-ui/core/Typography';

import CronForm from './CronForm';

export default ({ id, crons, setCrons }) => {
  const castId = +id;
  const cron = crons.find(cron => cron.id == castId);

  if(!cron) {
    return (
      <Typography variant="body1">
        Scheduled job not found. <Link to="/app/email-marketing">Go back.</Link>
      </Typography>
    );
  }
  
  return (
    <>
      <Helmet title="Editing a Scheduled Job"/>
      <CronForm
        id={+id}
        data={cron}
        setCrons={setCrons}
      />
    </>
  )
}