// this creates the entire object for the complete form
export const createInitialValues = (shape: Array<any>) => shape.reduce((acc, { name, type }) => {
  const data = createEmpty(name)(shape);

  return {
    ...acc,
    [name]: (type === 'single' ? data : [data])
  };
}, {});

// this creates an empty object for individual sections
export const createEmpty = (sectionName: string) => (shape: Array<any>) =>
  shape
    .find(section => section.name === sectionName)
    .fields
    .reduce((acc, { name, value }) => ({
      ...acc,
      [name]: value || ''
    }), {});

export const filterByKeys = (keys: Array<string|number>) => (object: Object) => Object.keys(object)
  .reduce((acc, key) => {
    if(keys.includes(key)) {
      acc[key] = object[key];
    }

    return acc;
  }, {});

export const keyArrayToEmptyObject = (arr: Array<number|string>) => arr.reduce((acc, key) => ({
  ...acc,
  [key]: ''
}), {});