import React, { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

export default ({ id, className, removeJob }) => {
  const { user } = useFirebase();
  const { completeAction } = useSnackbar();

  const [submitting, setSubmitting] = useState(false);

  async function handleClick() {
    setSubmitting(true);

    completeAction(async() => {
      const response = await sendApiRequest('/users/crons/' + id, user, 'delete');

      if(response.status !== 'success') {
        setSubmitting(false);
        throw new Error('Something went wrong. Please try again.');
      }

      removeJob(id);
      return 'Scheduled job successfully deleted!';
    });
  }

  if(submitting) {
    return (<CircularProgress/>);
  }

  return (
    <DeleteForeverIcon
      fontSize="large"
      onClick={handleClick}
      className={null || className}
    />
  );
}