import React, { createContext, useEffect, useState, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import LinearProgress from '@material-ui/core/LinearProgress';

import { SnackbarContext } from 'contexts/SnackbarContext';

const FirebaseContext = createContext();

const FirebaseConsumer = FirebaseContext.Consumer;

const FirebaseProvider = function({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(null);
  const [db, setDb] = useState(null);
  const { openSnackbar } = useContext(SnackbarContext);
  
  useEffect(() => {
    let app;
  
    try {
  
      app = firebase.app();
  
    } catch (e) {
  
      const projectId = process.env.GATSBY_FIREBASE_PROJECT_ID;
    
      app = firebase.initializeApp({
        apiKey: process.env.GATSBY_FIREBASE_API_KEY,
        authDomain: projectId + '.firebaseapp.com',
        projectId,
        databaseUrl: 'https://' + projectId + '.firebaseio.com'
      });
  
    }
  
    const firebaseAuth = app.auth();
    const firestore = app.firestore();
  
    setAuth(firebaseAuth);
    setDb(firestore);

    firebaseAuth.getRedirectResult()
      .catch(async(e) => {
        if (e.code === 'auth/account-exists-with-different-credential') {
          // show the snackbar and list providers they can use
          // add this account to local storage for retrieval
          const providers = await auth.fetchSignInMethodsForEmail(e.email);
          openSnackbar(
            `Please log in with ${providers.length > 1 ? 'one of ' : ''}the following to link your account: ` + providers.join(', ') + '.',
            'error'
          );
    
          localStorage.setItem('pendingCredential', JSON.stringify(e.credential));
        }
      });

    const unsubscribe = firebaseAuth.onAuthStateChanged(firebaseUser => {
      // console.log(firebaseUser);
    
      // TODO: the above function protects against double sending on account creation; however it prevents a person's name from showing up in the email verification; this could also be triggered on hard refresh or email change in profile
    
      setUser(firebaseUser);
      setIsLoaded(true);
    
      // email verification handling
      if (!firebaseUser || firebaseUser.emailVerified) {
        // no user or the email is already verified
        return;
      }

      if (
        firebaseUser.displayName === null
        && firebaseUser.providerData.length === 1
        && firebaseUser.providerData[0].providerId === 'password'
      ) {
        // an initial password signup with no display name; don't send verification until name change
        return;
      }

      // verify the user email
      firebaseUser.sendEmailVerification({
        url: process.env.GATSBY_BASE_URL + '/app/auth/login',
        handleCodeInApp: true
      })
        .then(() => {
          // pop up a snackbar notification?
          openSnackbar('A verification link has been sent to your email.', 'success');
        })
        .catch(e => openSnackbar(e.message, 'error'));
    
      if (firebaseUser) {
        const pendingCredential = localStorage.getItem('pendingCredential');
    
        if (pendingCredential) {
          try {
            const parsedCredential = JSON.parse(pendingCredential);
            let finalCredential;
    
            if (parsedCredential.providerId === 'facebook.com') {
              finalCredential = firebase.auth.FacebookAuthProvider.credential(parsedCredential.accessToken);
            } else if (parsedCredential.providerId === 'google.com') {
              finalCredential = firebase.auth.GoogleAuthProvider.credential(null, parsedCredential.accessToken);
            }
    
            firebaseUser.linkAndRetrieveDataWithCredential(finalCredential)
              .then(() => {
                localStorage.removeItem('pendingCredential');
                openSnackbar('Accounts have been successfully linked!', 'success');
              })
              .catch(e => openSnackbar(e.message, 'error'));
          } catch (e) {
            
            openSnackbar(e.message, 'error');

          }
        }
      }
    });

    return unsubscribe;
  }, []);

  if(!isLoaded) {
    return (<LinearProgress/>);
  }

  return (
    <FirebaseContext.Provider value={{
      auth,
      db,
      user
    }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export {
  FirebaseConsumer,
  FirebaseContext,
  FirebaseProvider
}