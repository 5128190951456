import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import styles from './RolesModal.module.css';

function reduceRoles(acc, { name }) {
  return {
    ...acc,
    [name]: false
  };
}

export default ({ roles, liftRoles }) => {
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(roles.reduce(reduceRoles, {}));

  function closeModal() {
    liftRoles(selected);
    setOpen(false);
  }

  function handleClick(role) {
    setSelected({
      ...selected,
      [role]: !selected[role]
    });
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">What role?</DialogTitle>
      <div className={styles.dialogInner}>
        <Typography variant="subtitle1">Select all that apply.</Typography>
        {roles.map(({ label, name }) => (
          <FormGroup row key={name} style={{ marginBottom: '10px' }}>
            <Button
              size="large"
              variant="contained"
              color={selected[name] ? "secondary" : "default"}
              onClick={() => handleClick(name)}
            >
              {label}
            </Button>
          </FormGroup>
        ))}
        <FormGroup row>
          <Button onClick={closeModal} variant="contained" color="primary">Confirm</Button>
        </FormGroup>
      </div>
    </Dialog>
  );
}