import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import OpenEscrowForm from '../OpenEscrowForm';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { SnackbarContext } from 'contexts/SnackbarContext';

import inputs from './refinance-inputs';

export default ({ id }) => {
  const [data, setData] = useState(null);
  const { openSnackbar } = useContext(SnackbarContext);
  const { db, user } = useContext(FirebaseContext);

  async function fetchRecord(id) {
    setData(null);

    try {
      const doc = await db
        .collection('users')
        .doc(user.uid)
        .collection('refinanceOpenEscrows')
        .doc(id)
        .get();
  
      const { createdAt, updatedAt, ...rest } = doc.data();

      setData(rest);
  
    } catch(e) {
      openSnackbar(e.message, 'error');
    }
  }

  useEffect(() => {
    fetchRecord(id)
  }, [id]);

  if (!data) {
    return (
      <CircularProgress />
    );
  }

  return (
    <OpenEscrowForm
      id={id}
      record={data}
      inputs={inputs}
      type="refinance"
    />
  );
}