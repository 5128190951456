import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';

import { withStyles } from '@material-ui/core/styles';

import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';

// Dashboard Pro
import Card from 'components/Card/Card';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

const LoginPage = ({ classes }) => {
  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  useEffect(() => {
    setTimeout(() => setCardAnimation(''), 700);
  }, []);
  
  return (
    <div className={classes.container}>
      <Helmet title="Login - Replaix"/>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card login className={classes[cardAnimation]}>
            {showForgotPassword ? (
              <ForgotPasswordForm
                classes={classes}
                setShowForgotPassword={setShowForgotPassword}
              />
            ) : (
              <LoginForm
                classes={classes}
                setShowForgotPassword={setShowForgotPassword}
              />
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);