import React, { useContext, useState } from 'react';

import OpenEscrowForm from '../OpenEscrowForm';
import RolesModal from '../../../components/RolesModal';

import { FirebaseContext } from 'contexts/FirebaseContext';

import inputs from './purchase-inputs';

const roles = [
  {
    name: 'listingBrokers',
    label: 'Listing Agent'
  },
  {
    name: 'sellingBrokers',
    label: 'Selling Agent'
  },
  {
    name: 'transactionCoordinatorSeller',
    label: 'Transaction Coordinator (Seller)'
  },
  {
    name: 'transactionCoordinatorBuyer',
    label: 'Transaction Coordinator (Buyer)'
  }
];

export default () => {
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [profile, setProfile] = useState(null);
  const { db, user } = useContext(FirebaseContext);

  async function liftRoles(roles) {
    if(Object.keys(roles).some(key => roles[key])) {
      // get the profile info
      const doc = await db
        .collection('users')
        .doc(user.uid)
        .get();
      
      if(doc.exists) {
        setProfile(doc.data());
      } else {
        setProfile({});
      }
    }

    setSelectedRoles(roles);
  }

  if(selectedRoles) {
    return (
      <OpenEscrowForm
        profile={profile}
        selectedRoles={selectedRoles}
        inputs={inputs}
        type="purchase"
      />
    );
  }

  return (<RolesModal liftRoles={liftRoles} roles={roles}/>);
}