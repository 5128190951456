import React from 'react';

import { Helmet } from 'react-helmet';

import CronForm from './CronForm';

export default ({ setCrons }) => (
  <>
    <Helmet title="Schedule a new email job"/>
    <CronForm setCrons={setCrons}/>
  </>
)