import React, { useState } from 'react';

import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import { useSnackbar } from 'utilities/hooks';

const styles = {
  thumb: {},
  checked: {},
  disabled: {},
  track: {},
  colorPrimary: {
    color: 'red',
    '&$checked': {
      color: 'green',
      '& $track': {
        backgroundColor: 'green'
      }
    },
    '&$disabled': {
      '& $thumb': {
        color: 'lightgray'
      }
    }
  }
};

function AppSwitch({ classes, enabled, handleEnable, handleDisable }) {
  const [pending, setPending] = useState(false);

  const { completeAction } = useSnackbar();

  async function handleClick() {
    setPending(true);

    if(enabled) {
      await completeAction(handleDisable);
    } else {
      await completeAction(handleEnable);
    }

    setPending(false);
  }

  return (
    <Switch
      color="primary"
      onClick={handleClick}
      checked={enabled}
      disabled={pending}
      classes={classes}
    />
  );
}

export default withStyles(styles)(AppSwitch)