import React, { createContext, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import styles from './ModalContext.module.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState('');

  function openModal(title, content) {
    setTitle(title);
    setContent(content);
    setOpen(true);
  }

  function closeModal() {
    setTitle('');
    setContent(null);
    setOpen(false);
  }

  return (
    <ModalContext.Provider value={{
      openModal,
      closeModal,
      setTitle
    }}>
      {children}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container justify="space-between">
          <Grid item>
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          </Grid>
          <Grid item className={styles.closeButton}>
            <CloseIcon onClick={closeModal}/>
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText
            component="div"
            id="alert-dialog-slide-description"
          >
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </ModalContext.Provider>
  );
}

export {
  ModalContext,
  ModalProvider
}