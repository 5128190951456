import { useContext } from 'react';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { SnackbarContext } from 'contexts/SnackbarContext';
import { ModalContext } from 'contexts/ModalContext';

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function useModal() {
  return useContext(ModalContext);
}