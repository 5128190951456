import React, { useContext } from 'react';

import { navigate } from '@reach/router';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import EnhancedField from 'siteComponents/EnhancedField';

import { SnackbarContext } from 'contexts/SnackbarContext';
import inputs from '../form-inputs';

import { filterObjectByKeys } from 'utilities/functions.tsx';

import styles from './UpdateContactForm.module.css';

const validationSchema = object().shape({
  firstName: string(),
  lastName: string(),
  email: string()
    .required('Please enter an email address.')
    .email('Please enter a valid email address.')
});

export default ({ db, user, id, data }) => {
  const { openSnackbar } = useContext(SnackbarContext);

  async function handleSubmit(data, actions) {
    try {

      await db
        .collection('users')
        .doc(user.uid)
        .collection('contacts')
        .doc(id)
        .set(data, { merge: true });
      
      openSnackbar('Contact successfully updated!', 'success');

      navigate('/app/contacts');

    } catch(e) {

      openSnackbar(e.message, 'error');

    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={filterObjectByKeys(data, 'firstName', 'lastName', 'email')}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => (
        <Form className={styles.form}>
          {inputs.map(input => (
            <EnhancedField
              key={input.name}
              {...input}
              fullWidth={true}
            />
          ))}
          <div className={styles.submitContainer}>
            {isSubmitting ? (<CircularProgress/>) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            )}
          </div>
        </Form>
      )}
    />
  );
}