import React, { useEffect, useState } from 'react';

import { Link } from '@reach/router';

import AppBar from '@material-ui/core/AppBar';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import UpdateContactForm from './UpdateContactForm';

import styles from './SingleContact.module.css';

export default props => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const contact = props.contacts.find(single => single.id === props.id);

    if(contact) {
      setData(contact.data());
    } else {
      setData(false);
    }
  }, []);

  let inner = null;

  if(data === false) {
    inner = (
      <div>This contact doesn't exist.</div>
    )
  } else if (data) {
    inner = (
      <UpdateContactForm {...props} data={data}/>
    );
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Link to="../">
            <BackIcon/>
          </Link>
          <Typography
            variant="h5"
            color="inherit"
          >
            {data ? [data.firstName, data.lastName].join(' ') : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={styles.container}>
        <Grid container>
          <Grid item md={6} lg={4}>
            {inner}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}