import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Formik, Form, FieldArray } from 'formik';
import { array, object, string } from 'yup';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { ModalContext } from 'contexts/ModalContext';
import EnhancedField from 'siteComponents/EnhancedField';

import styles from './SendForm.module.css';

import { sendApiRequest } from 'utilities/api';

const initialValues = {
  emails: [{ email: '' }]
};

const emailAddress = object().shape({
  email: string()
    .email('Please enter a valid email address.')
    .required('Please enter an email address.')
});

const validationSchema = object().shape({
  emails: array().of(emailAddress)
});

export default ({ endpoint }) => {
  const { user } = useContext(FirebaseContext);
  const { setTitle } = useContext(ModalContext);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState({
    message: null,
    style: 'success'
  });

  function handleSubmit(values) {
    setSubmitting(true);

    sendApiRequest(endpoint, user, 'post', {
      emails: values.emails.map(({ email }) => email)
    })
      .then(response => {
        const numberReceived = response.results.total_accepted_recipients || 0;

        if(numberReceived === 0) throw new Error('Emails not sent.')

        setSubmitting(false);
        setSuccess(true);
        setTitle('Success!')
        setResult({
          message: `Your email${numberReceived > 1 ? 's have' : ' has'} been sent.`,
          style: 'success'
        });
      })
      .catch(e => {
        setSubmitting(false);
        setSuccess(false);
        setResult({
          message: 'Something went wrong! ' + e.message,
          style: 'failure'
        })
      });
  }

  return (
    <>
      {success ? (
        <div className={styles.success}>{result.message}</div>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            render={({ values }) => (
              <Form>
                <FieldArray
                  name="emails"
                  render={({ push, remove }) => (
                    <>
                      {values.emails.map((_, i) => (
                        <Grid className={styles.inputContainer} container key={i}>
                          <Grid item className={styles.inputWrap}>
                            <EnhancedField
                              type="email"
                              name={'emails.' + i + '.email'}
                              label="Email Address"
                              fullWidth={true}
                            />
                          </Grid>
                          {i !== 0 && (
                            <Grid item>
                              <Fab
                                color="secondary"
                                size="small"
                                aria-label="remove"
                                onClick={() => remove(i)}
                              >
                                <RemoveIcon />
                              </Fab>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                      <Grid container justify="space-between">
                        <Grid item>
                          {values.emails.length < 5 && (
                            <Fab
                              color="secondary"
                              size="small"
                              aria-label="add"
                              onClick={() => push({ email: '' })}
                            >
                              <AddIcon />
                            </Fab>
                          )}
                        </Grid>
                        <Grid item>
                          {submitting ? (
                            <CircularProgress/>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                            >
                              Send
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                />
              </Form>
            )}
          />
          {result.style === 'failure' && (
            <div className={styles[result.style]}>{result.message}</div>
          )}
        </>
      )}
    </>
  );
}