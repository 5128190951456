import React, { useState, useEffect } from 'react';

import { Router } from '@reach/router';
import { Helmet } from 'react-helmet';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useFirebase } from 'utilities/hooks';

import AddNew from './schedule/AddNew';
import EmailMarketingDefault from './default/EmailMarketingDefault';
import PageWrapper from 'appComponents/PageWrapper';
import SingleCron from './schedule/SingleCron';

import { mapFirestoreDoc } from 'utilities/functions';
import { sendApiRequest } from 'utilities/api';

export default () => {
  const [crons, setCrons] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(null);

  const { db, user } = useFirebase();

  useEffect(() => {
    const userDocRef = db
      .collection('users')
      .doc(user.uid);

    // const unsubscribeCrons = userDocRef
    //   .collection('crons')
    //   .orderBy('name')
    //   .onSnapshot(snap => {
    //     setCrons(snap.docs.map(mapFirestoreDoc));
    //   });
    sendApiRequest('/users/crons', user)
      .then(({ cronJobs }) => setCrons(cronJobs));
    
    const unsubscribeSubscriptions = userDocRef
      .collection('subscriptions')
      .onSnapshot(snap => {
        if(!snap.docs.length) return setHasActiveSubscription(false);
        return setHasActiveSubscription(snap.docs[0].data().status === 'active');
      });
    
    const unsubscribeTemplates = userDocRef
      .collection('templates')
      .onSnapshot(snap => {
        setTemplates(snap.docs.map(mapFirestoreDoc))
      });
    
    // return function() {
    //   unsubscribeCrons();
    //   unsubscribeTemplates();
    // };
    return () => {
      unsubscribeTemplates();
      unsubscribeSubscriptions();
    }
  }, []);

  return (
    <PageWrapper>
      <Helmet title="Email Marketing"/>
      {crons === null || templates === null || hasActiveSubscription === null ? (
        <CircularProgress/>
      ) : (
        <Router primary={false}>
          <AddNew
            path="schedule/new"
            db={db}
            user={user}
            setCrons={setCrons}
          />
          <SingleCron
            path="schedule/:id"
            crons={crons}
            setCrons={setCrons}
          />
          <EmailMarketingDefault
            path="/"
            crons={crons}
            setCrons={setCrons}
            templates={templates}
            hasActiveSubscription={hasActiveSubscription}
            db={db}
            user={user}
          />
        </Router>
      )}
    </PageWrapper>
  );
}