export default [
  {
    name: 'property',
    label: 'Property Information',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'address',
        label: 'Property Address',
        autofocus: true,
        required: true
      },
      {
        name: 'propertyType',
        label: 'Property Type',
        type: 'select',
        options: [
          'Single Family Residence',
          'Condo/Townhome',
          '2 Units',
          '3 Units',
          '4 Units',
          '5+ Units',
          'Other'
        ]
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'applicationDate',
        label: 'Application Date',
        // type: 'date'
      },
      {
        name: 'apn',
        label: 'APN'
      },
      {
        name: 'propertyValue',
        label: 'Property Value'
      },
      {
        name: 'loanAmount',
        label: 'Loan Amount'
      },
      {
        name: 'loanType',
        label: 'Loan Type',
        type: 'select',
        options: [
          'Conv',
          'FHA',
          'VA'
        ]
      },
      {
        name: 'cashOutOrRateAndTerm',
        label: 'Cash Out or Rate and Term',
        type: 'radio',
        options: [
          'Cash Out',
          'Rate and Terms'
        ]
      },
      {
        name: 'impounds',
        label: 'Impounds',
        type: 'radio',
        options: [
          'Yes',
          'No'          
        ]                                                  
      },      
    ]
  },
  {
    name: 'escrow',
    label: 'Escrow Information',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'name',
        label: 'Settlement Agent/Escrow Officer'
      },
      {
        name: 'number',
        label: 'Escrow Number'
      },
      {
        name: 'company',
        label: 'Escrow Company'
      },
      {
        name: 'address',
        label: 'Escrow Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'fax',
        label: 'Fax',
        type: 'tel'
      }
    ]
  },
  {
    name: 'borrowers',
    label: 'Borrower',
    singular: 'Borrower',
    type: 'multiple',
    span: true,
    fields: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'        
      }
    ]
  },
  {
    name: 'lender',
    label: 'Lender',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'officePhone',
        label: 'Office Phone',
        type: 'tel'
      },
      {
        name: 'name',
        label: 'Loan Officer'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'
      },
      {
        name: 'fax',
        label: 'Fax',
        type: 'tel'
      },
      {
        name: 'processorName',
        label: 'Processor Name'
      },
      {
        name: 'processorEmail',
        label: 'Processor Email',
        type: 'email'
      }
    ]
  },
  {
    name: 'title',
    label: 'Title',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'titleOfficer',
        label: 'Title Officer'
      },
      {
        name: 'creditTo',
        label: 'Credit To'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'transactionCoordinator',
    label: 'Transaction Coordinator',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'fee',
        label: 'Fee'
      },
      {
        name: 'paidBy',
        label: 'Paid by',
        type: 'radio',
        options: [
          'Lender',
          'Client'
        ]
      }
    ]
  },
  {
    name: 'hazardInsurance',
    label: 'Hazard Insurance',
    type: 'single',
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'hoa',
    label: 'HOA',
    type: 'single',
    fields: [
      {
        name: 'name',
        label: 'HOA Name'
      },
      {
        name: 'company',
        label: 'Management Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'comments',
    label: 'Comments',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'comments',
        label: 'Comments',
        type: 'textarea',
        multiline: true,
        fullWidth: true
      }
    ]
  }
];