import React, { useRef, useState } from 'react'

import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { Editor } from 'slate-react';
import Html from 'slate-html-serializer';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import LooksOne from '@material-ui/icons/LooksOne';
import LooksTwo from '@material-ui/icons/LooksTwo';
import FormatQuote from '@material-ui/icons/FormatQuote';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import FormatItalic from '@material-ui/icons/FormatItalic';

import TestMessageForm from './TestMessageForm';
import EnhancedField from 'siteComponents/EnhancedField';

import { isKeyHotkey } from 'is-hotkey';

import { useFirebase, useModal, useSnackbar } from 'utilities/hooks';

import styles from './TemplateForm.module.css';

const validationSchema = object().shape({
  subject: string().required('Please enter a subject line.'),
  html: object()
});

const BLOCK_TAGS = {
  blockquote: 'quote',
  p: 'paragraph',
  h1: 'heading-one',
  h2: 'heading-two'
}

// Add a dictionary of mark tags.
const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underline',
}

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: 'block',
          type: type,
          data: {
            className: el.getAttribute('class'),
          },
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph':
            return <p className={obj.data.get('className')}>{children}</p>
          case 'block-quote':
            return <blockquote>{children}</blockquote>
          case 'heading-one':
            return <h1>{children}</h1>
          case 'heading-two':
            return <h2>{children}</h2>
          default:
            return children;
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: 'mark',
          type: type,
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>
          case 'italic':
            return <em>{children}</em>
          case 'underline':
            return <u>{children}</u>
          default:
            return children;
        }
      }
    },
  },
];

const html = new Html({ rules });

const DEFAULT_NODE = 'paragraph';

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlinedHotkey = isKeyHotkey('mod+u');

const componentStyles = {
  activeIcon: {
    backgroundColor: 'white'
  },
  appBar: {
    backgroundColor: '#eeeeee',
    border: '1px solid lightgray',
    borderBottom: '0',
    boxShadow: 'none',
    marginTop: '25px'
  }
};

function TemplateForm({ classes, template = null }) {
  const { completeAction } = useSnackbar();
  const { user, db } = useFirebase();
  const { openModal } = useModal();

  const templatesRef = db
    .collection('users')
    .doc(user.uid)
    .collection('templates');

  const initialVal = html.deserialize(template ? template.html : '<p></p>');

  const [value, setValue] = useState(initialVal);
  // const [editor, setEditor] = useState(null);
  const editor = useRef();
  
  const [initialValues] = useState({
    subject: template ? template.subject : '',
    html: initialVal
  });

  async function handleSubmit(values, actions) {
    const content = html.serialize(values.html);
    const timestamp = Date.now();

    await completeAction(async() => {
      if(template) {

        await templatesRef
          .doc(template.id)
          .set({
            subject: values.subject,
            html: content,
            updatedAt: timestamp
          }, { merge: true });

      } else {

        await templatesRef
          .add({
            subject: values.subject,
            html: content,
            createdAt: timestamp,
            updatedAt: timestamp
          })

      }

      return (
        <>
          <p>Email template successfully saved!</p>
          <p>Your email will be sent on your next scheduled mailing.</p>
        </>
      );
    });

    actions.setSubmitting(false);
  }

  // function handleChange({ value: newVal }) {
  //   setValue(newVal);
  // }

  /**
   * On key down, if it's a formatting command toggle a mark.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @return {Change}
   */

  function onKeyDown(event, editor, next) {
    let mark;

    if (isBoldHotkey(event)) {
      mark = 'bold';
    } else if (isItalicHotkey(event)) {
      mark = 'italic';
    } else if (isUnderlinedHotkey(event)) {
      mark = 'underlined';
    } else {
      return next();
    }

    event.preventDefault();
    editor.toggleMark(mark);
  }

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  const hasMark = type => value.activeMarks.some(mark => mark.type === type);

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  const hasBlock = type => value.blocks.some(node => node.type === type);

  /**
   * Render a mark-toggling AppBar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  function MarkButton({ type, icon: Icon, label }) {
    return (
      <IconButton
        classes={hasMark(type) ? { root: classes.activeIcon } : null}
        onMouseDown={e => handleMarkClick(e, type)}
        aria-label={label}
      >
        <Icon/>
      </IconButton>
    )
  }

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  function handleMarkClick(e, type) {
    e.preventDefault();
    editor.current.toggleMark(type);
  }

  /**
   * Render a block-toggling AppBar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  function BlockButton({ type, icon: Icon, label }) {
    let isActive = hasBlock(type);

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { document, blocks } = value;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = hasBlock('list-item') && parent && parent.type === type;
      }
    }

    return (
      <IconButton
        classes={isActive ? { root: classes.activeIcon } : null }
        onMouseDown={e => handleBlockClick(e, type)}
        aria-label={label}
      >
        <Icon/>
      </IconButton>
    );
  }

    /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  function handleBlockClick(e, type) {
    e.preventDefault();
    const { document } = value;

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = hasBlock(type)
      const isList = hasBlock('list-item')

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list')
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = hasBlock('list-item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list')
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
          )
          .wrapBlock(type)
      } else {
        editor.setBlocks('list-item').wrapBlock(type)
      }
    }
  }

  function InsertButton({ type, label }) {
    return (
      <Button
        onMouseDown={e => handleButtonClick(e, type)}
      >
        {label}
      </Button>
    );
  }

  function handleButtonClick(e, type) {
    e.preventDefault();
    editor.current.insertText('{{' + type + '}}');
  }

  /**
   * Render a Slate node.
   *
   * @param {Object} props
   * @return {Element}
   */

  function renderBlock(props, _, next) {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      default:
        return next();
    }
  }


  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  function renderMark(props, _, next) {
    const { children, mark, attributes } = props

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>
      case 'italic':
        return <em {...attributes}>{children}</em>
      case 'underlined':
        return <u {...attributes}>{children}</u>
      default:
        return next();
    }
  }

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ isSubmitting, values, errors, setFieldValue, setTouched }) => (
          <Form>
            <EnhancedField
              name="subject"
              type="text"
              label="Email Subject Line"
            />
            <AppBar position="static" classes={{ root: classes.appBar }}>
              <Toolbar>
                <MarkButton type="bold" icon={FormatBold} label="Bold"/>
                <MarkButton type="italic" icon={FormatItalic} label="Italic"/>
                <MarkButton type="underlined" icon={FormatUnderlined} label="Underline"/>
                <BlockButton type="heading-one" icon={LooksOne} label="Heading 1"/>
                <BlockButton type="heading-two" icon={LooksTwo} label="Heading 2"/>
                <BlockButton type="block-quote" icon={FormatQuote} label="Quote"/>
                <BlockButton type="numbered-list" icon={FormatListNumbered} label="Numbered List"/>
                <BlockButton type="bulleted-list" icon={FormatListBulleted} label="Bullet List"/>
                <InsertButton type="first_name" label="First Name"/>
                <InsertButton type="last_name" label="Last Name"/>
              </Toolbar>
            </AppBar>
            <Editor
              className={styles.editor}
              spellCheck
              autoFocus
              placeholder="Enter your template text here."
              ref={editor}
              value={values.html}
              onChange={({ value: newVal }) => {
                setFieldValue('html', newVal);
              }}
              onKeyDown={onKeyDown}
              renderBlock={renderBlock}
              renderMark={renderMark}
            />
            <Grid container justify="space-between">
              <Grid item>
                {isSubmitting ? (
                  <CircularProgress/>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                )}
              </Grid>
              {template !== null && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => openModal('Send a Test Message (Saved Template)', <TestMessageForm/>)}
                  >
                    Send a Test Message
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(componentStyles)(TemplateForm)