import React, { useEffect, useRef, useState } from 'react';
import * as paypalCheckout from 'braintree-web/paypal-checkout';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useFirebase, useSnackbar } from 'utilities/hooks';

import { sendApiRequest } from 'utilities/api';

export default ({
  client,
  customer,
  onSuccess = null,
  setCustomer,
  setIsEditing,
  subscription
}) => {
  const paypalInstance = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useFirebase();
  const { completeAction } = useSnackbar();

  function renderPayPalButton() {
    /* eslint-disable-next-line no-undef */
    paypal.Button.render({
      env: process.env.GATSBY_BRAINTREE_ENVIRONMENT,
      style: {
        size: 'medium',
        // layout: 'vertical',
        fundingicons: false,
        // tagline: false
      },
      funding: {
        /* eslint-disable-next-line no-undef */
        disallowed: [paypal.FUNDING.CREDIT]
      },
  
      payment() {
        return paypalInstance.current.createPayment({
          flow: 'vault',
          locale: 'en_US',
          // billingAgreementDescription: ''
          // Your PayPal options here. For available options, see
          // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
        });
      },
  
      async onAuthorize(data, actions) {
        setIsSubmitting(true);

        await completeAction(async() => {
          const { nonce } = await paypalInstance.current.tokenizePayment(data);

          let message;

          if(!customer) {

            // create a new one
            const response = await sendApiRequest(
              '/users/billing/customer',
              user,
              'post',
              { nonce }
            );

            setCustomer(response.customer);

            message = 'Payment method successfully added!';

          } else if (customer.paymentMethods.length) {

            // swap out the payment method
            const currentMethod = customer.paymentMethods[0];

            const response = await sendApiRequest(
              '/users/billing/paymentMethod',
              user,
              'put',
              {
                nonce,
                token: currentMethod.token,
                subscriptionId: subscription ? subscription.id : null,
                to: 'paypal',
                from: currentMethod.imageUrl.indexOf('paypal') >= 0 ? 'paypal' : 'cc'
              }
            );
    
            setCustomer({
              ...customer,
              paymentMethods: [response.paymentMethod]
            });

            message = 'Payment method successfully updated!';

          } else {

            // customer has no payment methods currently; add one

            const response = await sendApiRequest(
              '/users/billing/paymentMethod',
              user,
              'post',
              { nonce }
            );

            setCustomer({
              ...customer,
              paymentMethods: [response.paymentMethod]
            });

            message = 'Payment method successfully added!';

          }
          
          return message;
        }, () => {
          setIsEditing(false);
          if(typeof onSuccess === 'function') {
            onSuccess();
          }
        });
        
        setIsSubmitting(false);
        setIsEditing(false);
      },
  
      onCancel(data) {
        console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
      },
  
      onError(err) {
        console.error('checkout.js error', err);
      }
    }, '#paypal-button');
  }

  useEffect(() => {
    async function load() {
      try {

        const instance = await paypalCheckout.create({ client });
        paypalInstance.current = instance;
  
        /* eslint-disable-next-line no-undef */
        if(typeof paypal !== 'undefined') {
          renderPayPalButton();
        } else {
          const script = document.createElement('script');
          script.src = 'https://www.paypalobjects.com/api/checkout.js';
          script.setAttribute('log-level', 'warn');
          script.setAttribute('data-version-4', true);

          document.body.appendChild(script);

          script.addEventListener('load', renderPayPalButton);
        }

        return instance;

      } catch(e) {
        console.log(e);
      }
      
    }

    const instancePromise = load();
    return () => Promise
      .resolve(instancePromise)
      .then(inst => inst.teardown());
  }, []);

  return (
    <>
      <div id="paypal-button"></div>
      {isSubmitting && (
        <CircularProgress/>
      )}
      {!paypalInstance && (
        <CircularProgress/>
      )}
    </>
  )
}