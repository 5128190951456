import React, { Fragment, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import { auth as firebaseAuth } from 'firebase/app';
// import { Link } from '@reach/router';
import GatsbyLink from 'gatsby-link';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field, Formik, Form } from 'formik';
import { object, string } from 'yup';

// @material-ui/icons
import Description from "@material-ui/icons/Description";
import Cloud from "@material-ui/icons/Cloud";
import Group from "@material-ui/icons/Group";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Email from "@material-ui/icons/Email";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import InfoArea from "components/InfoArea/InfoArea";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CircularProgress from '@material-ui/core/CircularProgress';

import EnhancedField from 'siteComponents/EnhancedField';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { SnackbarContext } from 'contexts/SnackbarContext';

import registerPageStyle from "dashboard-pro-child/styles/components/registerPageStyle";
import styles from './SignUp.module.css';

const validationSchema = object().shape({
  name: string().required('Please enter your name.'),
  email: string().email('Please enter a valid email address.').required('Please enter your email address.'),
  password: string().required('Please enter your password.').min(6, 'Your password must be at least six characters.')
});

function SignUp({ classes }) {
  const [checked, setChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const { auth } = useContext(FirebaseContext);
  const { openSnackbar } = useContext(SnackbarContext);

  function handleToggle() {
    setChecked(!checked);
    setTermsError(checked);
  }

  async function handleSubmit({ name, email, password }) {
    if (!checked) {
      return setTermsError(true);
    }

    setSubmitting(true);

    try {

      const { user } = await auth.createUserWithEmailAndPassword(email, password);

      await user.updateProfile({ displayName: name });

      await user.sendEmailVerification({
        url: process.env.GATSBY_BASE_URL + '/app/auth/login',
        handleCodeInApp: true
      });

      openSnackbar('A verification link has been sent to your email address.', 'success');

    } catch (e) {

      openSnackbar(e.message.replace(' by another account', ''), 'error');

    }

    setSubmitting(false);
  }

  return (
    <div className={classes.container} style={{ minHeight: '0' }}>
      <Helmet>
        <title>Sign Up - Replaix</title>
      </Helmet>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={[classes.cardSignup, styles.wrap, styles.second].join(' ')}>
            <h2 className={classes.cardTitle}>Sign Up</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Simplify"
                    description="Replaix simplifies the work of Real Estate"
                    icon={Description}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Cloud"
                    description="Create and save your escrow details in the cloud"
                    icon={Cloud}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Share"
                    description="Quickly share your open escrow data with others (i.e. lenders, agents, escrow officers, etc.)"
                    icon={Group}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <div className={classes.center}>
                    {[
                      {
                        name: 'Facebook',
                        icon: 'fab fa-facebook-f',
                        provider: new firebaseAuth.FacebookAuthProvider()
                      },
                      // {
                      //   name: 'Twitter',
                      //   icon: 'fab fa-twitter',
                      //   provider: new firebaseAuth.TwitterAuthProvider()
                      // },
                      {
                        name: 'Google',
                        icon: 'fab fa-google',
                        provider: new firebaseAuth.GoogleAuthProvider()
                      }
                    ].map(({ name, icon, provider }) => (
                      <Fragment key={name}>
                        <Button
                          justIcon
                          round
                          color={name.toLowerCase()}
                          key={name}
                          onClick={() => auth.signInWithRedirect(provider)}
                        >
                          <i className={icon} />
                        </Button>
                        {' '}
                      </Fragment>
                    ))}
                    <h4 className={classes.socialTitle}>or enter your email</h4>
                  </div>
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      password: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    render={() => (
                      <Form>
                        <EnhancedField
                          name="name"
                          label="Name"
                          fullWidth
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircle className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            inputProps: {'data-test': 'name'}
                          }}
                        />
                        <EnhancedField
                          name="email"
                          label="Email"
                          fullWidth
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            inputProps: {'data-test': 'email'}
                          }}
                        />
                        <EnhancedField
                          name="password"
                          label="Password"
                          fullWidth
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <LockOutlined className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            type: 'password',
                            inputProps: {'data-test': 'password'}
                          }}
                        />
                        <Field
                          name="terms"
                          render={({ field, form }) => {
                            return (
                              <>
                                <FormControlLabel
                                  classes={{
                                    root: classes.checkboxLabelControl,
                                    label: classes.checkboxLabel
                                  }}
                                  control={
                                    <Checkbox
                                      name={field.name}
                                      tabIndex={-1}
                                      onClick={handleToggle}
                                      checkedIcon={
                                        <Check className={classes.checkedIcon} />
                                      }
                                      icon={<Check className={classes.uncheckedIcon} />}
                                      classes={{
                                        checked: classes.checked
                                      }}
                                      inputProps={{'data-test': 'terms-agreement'}}
                                    />
                                  }
                                  label={
                                    <span>
                                      I agree to the{" "}
                                      <GatsbyLink to="/terms" target="_blank">terms and conditions</GatsbyLink>.
                                    </span>
                                  }
                                />
                                {termsError && (
                                  <FormHelperText
                                    error={true}
                                    style={{ marginBottom: '20px' }}
                                  >
                                    Please agree to the terms and conditions.
                                  </FormHelperText>
                                )}
                              </>
                            );
                          }}
                        />
                        <div className={classes.center}>
                          {submitting ? (
                            <CircularProgress />
                          ) : (
                              <Button type="submit" round color="primary">
                                Get started
                            </Button>
                            )}
                        </div>
                      </Form>
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(SignUp);