import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import { ThemeProvider } from '@material-ui/core/styles';

import { SnackbarProvider } from 'contexts/SnackbarContext';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import { ModalProvider } from 'contexts/ModalContext';

import Routes from 'routes/Routes';

import theme from 'siteComponents/ThemeContext';

export default () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex,follow"/>
      <link href="https://use.fontawesome.com/releases/v5.0.7/css/all.css" rel="stylesheet" />
      <link href={withPrefix('/material-dashboard-pro-react.css')} rel="stylesheet" />
      <link href={withPrefix('/dashboard-overrides.css')} rel="stylesheet" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <FirebaseProvider>
          <ModalProvider>
            <Routes/>
          </ModalProvider>
        </FirebaseProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </>
)