import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

export default () => {
  const { user } = useFirebase();
  const { openSnackbar } = useSnackbar();

  const [deleting, setDeleting] = useState(false);

  async function handleDeleteRequest() {
    const confirmed = window.confirm('Are you sure? This cannot be undone!');

    if(confirmed) {
      setDeleting(true);

      try {

        const result = await sendApiRequest('/users/contacts', user, 'delete');

        if(!result.success) {
          throw new Error('Something went wrong. Please try your request again.', 'error');
        }

        openSnackbar('Success! Your contacts have been deleted!', 'success');

      } catch(e) {

        openSnackbar(e.message, 'error');

      }

      setDeleting(false);
    }
  }

  if(deleting) {
    return (<CircularProgress/>);
  }

  return (
    <Button
      style={{ marginLeft: '20px' }}
      variant="contained"
      color="secondary"
      onClick={handleDeleteRequest}
    >
      Delete List
    </Button>
  );
}