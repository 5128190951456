import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Cancellation from './Cancellation';

import { useFirebase, useModal, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

function getDateFormat(string) {
  const [year, month, day] = string.split('-');
  return [month, day, year].join('/');
}

export default ({ customer, subscription, setSubscription }) => {
  const [reactivating, setReactivating] = useState(false);
  const [upgrading, setUpgrading] = useState(false);
  const { closeModal, openModal } = useModal();
  const { user } = useFirebase();
  const { completeAction } = useSnackbar();

  function handleClick() {
    openModal('Cancel', (
      <Cancellation
        subscription={subscription}
        setSubscription={setSubscription}
        closeModal={closeModal}
      />
    ));
  }

  async function handleUpgrade() {
    setUpgrading(true);

    await completeAction(async() => {
      const response = await sendApiRequest(
        '/users/billing/subscriptions/' + subscription.id + '/upgrade',
        user,
        'put',
        {
          token: customer.paymentMethods[0].token
        }
      );

      if(response.result !== 'success') {
        throw new Error('Something went wrong. Please try again.');
      }

      setSubscription({
        ...subscription,
        changeDate: response.changeDate
      });
      return 'Your subscription will be changed to yearly on your next billing date.';
    });

    setUpgrading(false);
  }

  async function reactivate() {
    setReactivating(true);

    await completeAction(async() => {
      const response = await sendApiRequest(
        '/users/billing/subscriptions/' + subscription.id + '/reactivate',
        user,
        'put',
        {
          cronId: subscription.cronId || null
        }
      );

      if(response.result !== 'success') {
        throw new Error('Your request could not be processed. Please try again.');
      }

      setSubscription({
        ...subscription,
        ...response.subscription
      });
      return 'Your account was successfully reactivated!';
    });

    setReactivating(false);
  }

  return (
    <>
      <Typography variant="h3">Subscription</Typography>
      <p>Plan: {subscription.planId}{subscription.paidThroughDate ? <> &ndash; paid through {getDateFormat(subscription.paidThroughDate)}</> : ''}</p>
      {subscription.changeDate && (
        <p>You initiated a subscription change which will take effect on your next billing date.</p>
      )}
      {subscription.neverExpires !== false || typeof subscription.changeDate !== 'undefined' ? (
        <>
          <p>Next billing date: {getDateFormat(subscription.nextBillingDate)}</p>
          {upgrading ? (<CircularProgress/>) : (
            <>
              {subscription.planId === 'monthly' && typeof subscription.cronId === 'undefined' && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpgrade}
                  >
                    Upgrade to Yearly
                  </Button>
                  {' '}
                </>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
              >
                Cancel Subscription
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <div>
            <p>* Your plan was cancelled on {new Date(subscription.updatedAt).toLocaleDateString()}.</p>
            <p>To continue service, please click the reactivate button below.</p>
            {reactivating ? (<CircularProgress/>) : (
              <Button
                variant="contained"
                color="primary"
                onClick={reactivate}
              >
                Reactivate Now
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
}