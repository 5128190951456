import React, { memo } from 'react';

import { Link } from '@reach/router';

import Checkbox from '@material-ui/core/Checkbox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Switch from 'appComponents/Switch';
import UndoDelete from './UndoDelete';

import { useFirebase, useSnackbar } from 'utilities/hooks';

import styles from './Contact.module.css';

export default memo(({ data, id, isSelected, handleChange }) => {
  const { user, db } = useFirebase();
  const { completeAction } = useSnackbar();

  const contactRef = db
    .collection('users')
    .doc(user.uid)
    .collection('contacts')
    .doc(id);

  async function handleEnable() {
    await contactRef.set({
      active: true,
      updatedAt: Date.now()
    }, { merge: true });

    return 'Contact successfully enabled!';
  }

  async function handleDisable() {
    await contactRef.set({
      active: false,
      updatedAt: Date.now()
    }, { merge: true });

    return 'Contact successfully disabled!';
  }

  function handleDelete() {
    completeAction(async() => {
      await contactRef.delete();

      return (
        <>
          Contact successfully deleted!{' '}
          <UndoDelete
            db={db}
            id={id}
            userId={user.uid}
            data={data}/
          >
        </>
      );
    });
  }

  return (
    <>
      <TableRow
        hover
        selected={isSelected}
        aria-checked={isSelected}
      >
        <TableCell>
          <Checkbox
            checked={isSelected}
            onClick={() => handleChange(id)}
          />
        </TableCell>
        <TableCell>{data.firstName}</TableCell>
        <TableCell>{data.lastName}</TableCell>
        <TableCell>{data.email}</TableCell>
        <TableCell>
          <Switch
            enabled={data.active}
            handleEnable={handleEnable}
            handleDisable={handleDisable}
          />
        </TableCell>
        <TableCell>
          <DeleteForeverIcon
            fontSize="large"
            onClick={handleDelete}
            className={styles.hover}
          />
          <Link to={'/app/contacts/' + id}>
            <EditIcon
              fontSize="large"
              className={styles.hover}
            />
          </Link>
        </TableCell>
      </TableRow>
    </>
  )
})