import React, { useRef } from 'react';

import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { Link, navigate } from '@reach/router';

import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Papa from 'papaparse';

import EnhancedField from 'siteComponents/EnhancedField';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { pluralize } from 'utilities/functions.tsx';

import styles from './ContactsUpload.module.css';

function fuseList(list) {
  const lastIndex = list.length - 1;
  return list.slice(0, lastIndex).join(', ') + ', and ' + list[lastIndex];
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  file: ''
};

const validationSchema = object().shape({
  firstName: string(),
  lastName: string(),
  email: string().required('Please enter your email column header.'),
  file: string().required('Please supply a csv file to import.')
});

function columnNotFoundError(name) {
  return 'The column name of "' + name + '" that was entered is not spelled correctly. Please match the exact spelling and capitalization to the original file you are uploading.';
}

export default ({ contacts }) => {
  const fileInput = useRef(null);
  const { openSnackbar } = useSnackbar();
  const { db, user } = useFirebase();

  // function handleClick() {
  //   fileInput.current.click();
  // }

  async function insertRecord({ first_name, last_name, email }) {
    const contactsRef = db
      .collection('users')
      .doc(user.uid)
      .collection('contacts');

    // const { docs } = await contactsRef
    //   .where('email', '==', email)
    //   .get();

    // if(docs.length) {
    //   return 'skipped';
    // }

    const timestamp = Date.now();

    return contactsRef
      .add({
        firstName: first_name,
        lastName: last_name,
        email,
        active: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });
  }

  async function handleSubmit({ firstName, lastName, email }, actions) {
    try {
      const file = fileInput.current.files[0];
      // const fileReader = new FileReader();
      // fileReader.readAsDataURL(target.files[0]);

      // const result = await new Promise((resolve, reject) => {
      //   fileReader.onload = function(e) {
      //     resolve(e.target.result);
      //   };

      //   fileReader.onerror = function(e) {
      //     reject(e);
      //   };
      // });
      const parsed = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: res => resolve(res),
          error: e => reject(e),
          transform: val => val.trim(),
          skipEmptyLines: 'greedy'
        });
      });

      const {
        data,
        meta: {
          fields
        }
      } = parsed;

      if(!fields.includes(email)) {
        throw new Error(columnNotFoundError(email));
      }

      [firstName, lastName].forEach(item => {
        if(item !== '' && !fields.includes(item)) {
          throw new Error(columnNotFoundError(item));
        }
      });

      const emails = new Set();
      const missing = [];
      const invalid = [];

      const transformedData = data
        .map((record, i) => {
          if(emails.has(record[email])) {
            return null
          }

          emails.add(record[email]);

          if(!record[email]) {
            missing.push(i + 1);
            return null;
          }
          
          if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(record[email])) {
            invalid.push(i + 1);
            return null;
          }

          return {
            first_name: firstName !== '' ? record[firstName] : '',
            last_name: lastName !== '' ? record[lastName] : '',
            email: record[email].toLowerCase()
          }
        })
        .filter(Boolean);

      const length = transformedData.length;
      const contactsLength = contacts.length;

      if(length) {
        // great, we got some valid addresses!
        let toBeInserted = transformedData;
        let skippedDueToOverage = 0;

        if(length > 2500 - contactsLength) {
          const remaining = 2500 - contactsLength;
          skippedDueToOverage = length - remaining;
          toBeInserted = transformedData.slice(0, remaining);
        }

        const currentEmails = new Set(contacts.map(contact => contact.data().email));
        
        const insertResult = await Promise.all(toBeInserted.filter(record => !currentEmails.has(record.email)).map(insertRecord));

        const skipped = insertResult
          .filter(res => res === 'skipped')
          .length;
        
        const inserted = insertResult.length - skipped;

        if(inserted === 0) {
          throw new Error('All records skipped as the emails are already in your contacts list.');
        }

        const message = fuseList([
          inserted + ' of ' + data.length + ' ' + pluralize('record', inserted) + ' inserted',
          skippedDueToOverage ? skippedDueToOverage + ' skipped (due to 2,500 list limit)' : null,
          skipped ? skipped + ' (' + pluralize('duplicate', skipped) + ')' : null
        ].filter(Boolean)) + '!';

        openSnackbar(
          <>
            <p>{message}</p>
            {missing.length !== 0 && (
              <p>The following rows were missing email addresses: {pluralize('line', missing.length)} {missing.join(', ')}.</p>
            )}
            {invalid.length !== 0 && (
              <p>The following rows contained invalid email addresses: {pluralize('line', invalid.length)} {invalid.join(', ')}.</p>
            )}
          </>,
          !missing.length && !invalid.length ? 'success' : 'warning',
          null
        );

        navigate('/app/contacts');

      } else {
        throw new Error('Your file does not have any contacts!');
      }

    } catch(e) {

      openSnackbar(e.message, 'error', null);

    }
    
    actions.setSubmitting(false);
  }

  return (
    <Paper className={styles.container}>
      <div className={styles.cancelContainer}>
        <Button
          component={Link}
          to="../"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
      {/* <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Detailed Instructions</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          
        </ExpansionPanelDetails>
      </ExpansionPanel> */}
      {/* <input
        ref={fileInput}
        type="file"
        accept=".csv"
        hidden
        onChange={handleSelect}
      />
      {isSubmitting ? (<CircularProgress/>) : (
        <Button variant="contained" color="primary" onClick={handleClick}>
          Upload Contacts
        </Button>
      )} */}
      <Typography variant="h4">Upload Instructions</Typography>
      <Typography component="div">
        <p>To import a spreadsheet from Excel or Google Sheets, first export your data into a CSV file.</p>

        <p><b>To export a CSV from Google Spreadsheets:</b></p>
        <ol>
          <li>
            Click the <b>File</b> menu, then click <b>Download as</b>, then <b>.csv</b>.
          </li>
        </ol>

        <p><b>To export a CSV from Microsoft Excel:</b></p>
        <ol>
          <li>Click the <b>File</b> menu, then the <b>Save as</b>... command.</li>
          <li>In the <b>Save as type</b> select menu, choose the <b>Comma Separated Values (.csv)</b> option.</li>
          <li>Click the <b>Save</b> button. If warning messages appear, select the <b>OK</b> or <b>Yes</b> button.</li>
        </ol>

        <p><b>NOTE: If the file you are uploading does not have first and last columns, leave blank. Otherwise, match the exact spelling and capitalization to the file you are uploading.</b></p>
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ isSubmitting, setFieldValue, touched, errors }) => (
          <Grid container>
            <Grid item md={6} lg={4}>
              <Form className={styles.inputContainer}>
                <EnhancedField
                  name="firstName"
                  label="First Name column header (optional)"
                  type="text"
                  fullWidth={true}
                />
                <EnhancedField
                  name="lastName"
                  label="Last Name column header (optional)"
                  type="text"
                  fullWidth={true}
                />
                <EnhancedField
                  name="email"
                  label="Email column header *"
                  type="text"
                  fullWidth={true}
                />
                {/* <EnhancedField
                  name="file"
                  label="Import File"
                  type="file"
                /> */}
                <div className={styles.fileInput}>
                  <input
                    name="file"
                    type="file"
                    onChange={e => setFieldValue('file', e.target.value)}
                    accept=".csv"
                    ref={fileInput}
                  />
                  {typeof touched.file !== 'undefined' && typeof errors.file !== 'undefined' && (
                    <FormHelperText error={true}>{errors.file}</FormHelperText>
                  )}
                </div>
                {isSubmitting ? (<CircularProgress/>) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Import Now
                  </Button>
                )}
              </Form>
            </Grid>
          </Grid>
        )}
      />
    </Paper>
  )
}