import React, { createContext, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import styles from './SnackbarContext.module.css';

const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [style, setStyle] = useState('success');
  const [autoHide, setAutoHide] = useState(4500);

  function openSnackbar(message, style, duration = 4500) {
    setOpen(true);
    setMessage(message);
    setStyle(style);
    setAutoHide(duration);
  }

  async function completeAction(func, onSuccess = null) {
    try {
      const resolved = await func();
      openSnackbar(resolved, 'success');
      if(typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch(e) {
      openSnackbar(e.message, 'error');
    }
  }

  function closeSnackbar() {
    setOpen(false);
  }

  return (
    <SnackbarContext.Provider value={{
      openSnackbar,
      closeSnackbar,
      completeAction
    }}>
      {children}
      <Snackbar
        open={open}
        onClose={closeSnackbar}
        autoHideDuration={autoHide}
      >
        <SnackbarContent
          message={message}
          className={styles[style]}
          action={[(
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={closeSnackbar}
            >
              <CloseIcon />
            </IconButton>
          )]}
        />
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export {
  SnackbarContext,
  SnackbarProvider
}