export default [
  {
    name: 'property',
    label: 'Property Information',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'address',
        label: 'Property Address',
        autofocus: true,
        required: true
      },
      {
        name: 'propertyType',
        label: 'Property Type',
        type: 'select',
        options: [
          'Single Family Residence',
          'Condo/Townhome',
          '2 Units',
          '3 Units',
          '4 Units',
          '5+ Units',
          'Other'
        ]
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'contractDate',
        label: 'Contract Date',
        // type: 'date'
      },
      {
        name: 'closingDate',
        label: 'Acceptance Date'
      },
      {
        name: 'numberCounterOffers',
        label: 'Number of Counter Offers'
      },
      {
        name: 'apn',
        label: 'APN'
      },
      {
        name: 'lockboxCode',
        label: 'Lockbox Code'
      },
      {
        name: 'salesPrice',
        label: 'Sales Price'
      },
      {
        name: 'inspectionContingency',
        label: 'Inspection Contingency'
      },
      {
        name: 'loanContingency',
        label: 'Loan Contingency'
      },
      // {
      //   name: 'creditForRepairs',
      //   label: 'Credit for Repairs/Other'
      // },
      {
        name: 'sellerPaidClosingCosts',
        label: 'Seller Paid Closing Costs'
      },
      {
        name: 'possessionAfterClose',
        label: 'Possession After Close'
      },
      {
        name: 'listingBrokerCommission',
        label: 'Listing Broker Commission'
      },
      {
        name: 'sellingBrokerCommission',
        label: 'Selling Broker Commission'
      }
    ]
  },
  {
    name: 'escrow',
    label: 'Escrow Information',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'name',
        label: 'Settlement Agent/Escrow Officer'
      },
      {
        name: 'number',
        label: 'Escrow Number'
      },
      {
        name: 'company',
        label: 'Escrow Company'
      },
      {
        name: 'address',
        label: 'Escrow Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'fax',
        label: 'Fax',
        type: 'tel'
      }
    ]
  },
  {
    name: 'sellers',
    label: 'Seller',
    singular: 'Seller',
    type: 'multiple',
    fields: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'        
      }
    ]
  },
  {
    name: 'buyers',
    label: 'Buyer',
    singular: 'Buyer',
    type: 'multiple',
    fields: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'
      }
    ]
  },
  {
    name: 'listingBrokers',
    label: 'Seller\'s Agent',
    type: 'multiple',
    singular: 'Listing Broker',
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'name',
        label: 'Listing Agent'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'
      }
    ]
  },
  {
    name: 'sellingBrokers',
    label: 'Buyer\'s Agent',
    singular: 'Selling Broker',
    type: 'multiple',
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'name',
        label: 'Selling Agent'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'
      }
    ]
  },
  {
    name: 'transactionCoordinatorSeller',
    label: 'Transaction Coordinator (Seller)',
    type: 'single',
    span: false,
    fields: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'fee',
        label: 'Fee'
      },
      {
        name: 'paidBy',
        label: 'Paid by',
        type: 'radio',
        options: [
          'Agent',
          'Client'
        ]
      }
    ]
  },
  {
    name: 'transactionCoordinatorBuyer',
    label: 'Transaction Coordinator (Buyer)',
    type: 'single',
    span: false,
    fields: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'fee',
        label: 'Fee'
      },
      {
        name: 'paidBy',
        label: 'Paid by',
        type: 'radio',
        options: [
          'Agent',
          'Client'
        ]
      }
    ]
  },
  {
    name: 'lender',
    label: 'Lender',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'city',
        label: 'City'
      },
      {
        name: 'state',
        label: 'State'
      },
      {
        name: 'zip',
        label: 'Zip'
      },
      {
        name: 'officePhone',
        label: 'Office Phone',
        type: 'tel'
      },
      {
        name: 'name',
        label: 'Loan Officer'
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email'
      },
      {
        name: 'phone',
        label: 'Cell',
        type: 'tel'
      },
      {
        name: 'fax',
        label: 'Fax',
        type: 'tel'
      },
      {
        name: 'processorName',
        label: 'Processor Name'
      },
      {
        name: 'processorEmail',
        label: 'Processor Email',
        type: 'email'
      }
    ]
  },
  {
    name: 'title',
    label: 'Title',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'titleOfficer',
        label: 'Title Officer'
      },
      {
        name: 'creditTo',
        label: 'Credit To'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'homeWarranty',
    label: 'Home Warranty',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'coverageNotToExceed',
        label: 'Not to Exceed ($)'
      }
    ]
  },
  {
    name: 'termite',
    label: 'Termite',
    type: 'single',
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'nhd',
    label: 'NHD',
    type: 'single',
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      },
      {
        name: 'escrowToOrder',
        label: 'Escrow to Order?',
        type: 'radio',
        options: [
          'Yes',
          'No'
        ]
      },
      {
        name: 'coverage',
        label: 'Coverage',
        type: 'select',
        options: [
          'Residential (1-4 Units)', 
          'Residential + Environmental (1-4 Units)',
          'Commercial (5+ Units)',
          'Commercial + Environmental (5+ Units)'
        ]
      },
    ]
  },
  {
    name: 'hazardInsurance',
    label: 'Hazard Insurance',
    type: 'single',
    fields: [
      {
        name: 'company',
        label: 'Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'hoa',
    label: 'HOA',
    type: 'single',
    fields: [
      {
        name: 'name',
        label: 'HOA Name'
      },
      {
        name: 'company',
        label: 'Management Company'
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel'
      }
    ]
  },
  {
    name: 'comments',
    label: 'Comments',
    type: 'single',
    span: true,
    fields: [
      {
        name: 'comments',
        label: 'Comments',
        type: 'textarea',
        multiline: true,
        fullWidth: true
      }
    ]
  }
];