import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import NavLink from 'appComponents/NavLink';
import { Link } from '@reach/router';
import GatsbyLink from 'gatsby-link';
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ContactsIcon from '@material-ui/icons/Contacts';
import Drawer from "@material-ui/core/Drawer";
import EmailIcon from '@material-ui/icons/Email';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
// import Button from "@material-ui/core/Button";
// import LocalOffer from '@material-ui/icons/LocalOffer';
// import MonetizationOn from '@material-ui/icons/MonetizationOn';

import DescriptionIcon from '@material-ui/icons/Description';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
// import AddIcon from '@material-ui/icons/Add';

// import PlusFab from '../components/PlusFab';

// core components
import HeaderLinks from './HeaderLinks';

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle";

import styles from './Sidebar.module.css';
import Avatar from '../../assets/images/avatar-icon.svg';

import { FirebaseContext } from '../contexts/FirebaseContext';
import { mergeClasses } from 'utilities/functions.tsx';

// import avatar from "assets/img/faces/avatar.jpg";

var ps;

const openEscrowTypes = [
  {
    label: 'Purchase',
    routeParam: 'purchase'
  },
  {
    label: 'Refinance',
    routeParam: 'refinance'
  }
];

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, headerLinks, links, user } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  render() {
    const {
      classes,
      // color,
      logo,
      image,
      // logoText,
      // routes,
      bgColor,
      rtlActive
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });
    // const collapseItemMini =
    //   classes.collapseItemMini +
    //   " " +
    //   cx({
    //     [classes.collapseItemMiniRTL]: rtlActive
    //   });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });
    const user = (
      <FirebaseContext.Consumer>
        {({ auth, user }) => (
          user && (
            <div className={userWrapperClass}>
              <div className={photo}>
                <img
                  src={user.photoURL || Avatar}
                  className={classes.avatarImg}
                  alt={user.displayName}
                />
              </div>
              <List className={classes.list}>
                <ListItem className={mergeClasses(classes.item, classes.userItem)}>
                  <span
                    className={mergeClasses(classes.itemLink, classes.userCollapseButton, styles.collapsible)}
                    onClick={() => this.openCollapse("openAvatar")}
                  >
                    <ListItemText
                      primary={user.displayName || 'Replaix User'}
                      secondary={
                        <b
                          className={mergeClasses(
                            caret,
                            classes.userCaret,
                            (this.state.openAvatar ? classes.caretActive : "")
                          )}
                        />
                      }
                      disableTypography={true}
                      className={mergeClasses(itemText, classes.userItemText)}
                    />
                  </span>
                  <Collapse in={this.state.openAvatar} unmountOnExit>
                    <List className={mergeClasses(classes.list, classes.collapseList)}>
                      <ListItem className={classes.collapseItem}>
                        <Link
                          to="profile"
                          className={mergeClasses(classes.itemLink, classes.userCollapseLinks)}
                        >
                          {/* <span className={collapseItemMini}>
                            P
                          </span> */}
                          <ListItemText
                            primary="Profile"
                            disableTypography={true}
                            className={collapseItemText}
                          />
                        </Link>
                      </ListItem>
                      <ListItem className={mergeClasses(classes.collapseItem, styles.signOut)}>
                        <div
                          className={mergeClasses(classes.itemLink, classes.userCollapseLinks)}
                          color="white"
                          aria-label="Sign Out"
                          onClick={() => auth.signOut()}
                        >
                          Sign Out
                        </div>
                      </ListItem>
                      {/* <ListItem className={classes.collapseItem}>
                        <Link
                          to="settings"
                          className={mergeClasses(classes.itemLink, classes.userCollapseLinks)}
                        >
                          <span className={collapseItemMini}>
                            S
                          </span>
                          <ListItemText
                            primary="Settings"
                            disableTypography={true}
                            className={collapseItemText}
                          />
                        </Link>
                      </ListItem> */}
                    </List>
                  </Collapse>
                </ListItem>
              </List>
            </div>
          )
        )}
      </FirebaseContext.Consumer>
    );
    const links = (
      <>
        <List className={classes.list}>
          {openEscrowTypes.map(({ label, routeParam }) => (
            <ListItem key={label} className={classes.item} style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 15px',
              alignItems: 'center'
            }}>
              <NavLink
                to={'/app/open-escrows/' + routeParam}
                className={classes.itemLink}
                activeClass={classes.collapseActive}
                style={{
                  margin: '0',
                  width: 'calc(100% - 50px)'
                }}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <DescriptionIcon/>
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
              <Fab
                component={Link}
                to={`/app/open-escrows/${routeParam}/new`}
                size="small"
                color="primary"
                aria-label="Add"
              >
                <AddIcon/>
              </Fab>
            </ListItem>
          ))}
          <ListItem key="contacts" className={classes.item}>
            <NavLink
              to={'/app/contacts'}
              className={classes.itemLink}
              activeClass={classes.collapseActive}
            >
              <ListItemIcon className={classes.itemIcon}>
                <ContactsIcon/>
              </ListItemIcon>
              <ListItemText
                primary="Contacts"
                disableTypography={true}
                className={itemText}
              />
            </NavLink>
          </ListItem>
          <ListItem key="email-marketing" className={classes.item}>
            <NavLink
              to={'/app/email-marketing'}
              className={classes.itemLink}
              activeClass={classes.collapseActive}
            >
              <ListItemIcon className={classes.itemIcon}>
                <EmailIcon/>
              </ListItemIcon>
              <ListItemText
                primary="Email Marketing"
                disableTypography={true}
                className={itemText}
              />
            </NavLink>
          </ListItem>
          <ListItem key="billing" className={classes.item}>
            <NavLink
              to={'/app/billing'}
              className={classes.itemLink}
              activeClass={classes.collapseActive}
            >
              <ListItemIcon className={classes.itemIcon}>
                <MonetizationOnIcon/>
              </ListItemIcon>
              <ListItemText
                primary="Billing"
                disableTypography={true}
                className={itemText}
              />
            </NavLink>
          </ListItem>
        </List>
        <List className={classes.list}>
          <ListItem
            className={classes.item}
            component="a" href="https://www.surveymonkey.com/r/6TB6H72"
            target="_blank" style={{
            padding: '0 15px'
          }}>
            <ListItemIcon className={classes.itemIcon}>
              <SentimentSatisfiedAltIcon/>
            </ListItemIcon>
            <ListItemText
              primary="Take our awesome survey!"
              disableTypography={true}
              className={itemText}
            />
          </ListItem>
        </List>
      </>
      // <List className={classes.list}>
      //   {routes.filter(({ sidebar }) => typeof sidebar !== 'undefined').map((prop, key) => {
      //     if (prop.redirect) {
      //       return null;
      //     }
      //     if (prop.collapse) {
      //       const navLinkClasses =
      //         classes.itemLink +
      //         " " +
      //         cx({
      //           [" " + classes.collapseActive]: this.activeRoute(prop.path)
      //         });
      //       const itemText =
      //         classes.itemText +
      //         " " +
      //         cx({
      //           [classes.itemTextMini]:
      //             this.props.miniActive && this.state.miniActive,
      //           [classes.itemTextMiniRTL]:
      //             rtlActive && this.props.miniActive && this.state.miniActive,
      //           [classes.itemTextRTL]: rtlActive
      //         });
      //       const collapseItemText =
      //         classes.collapseItemText +
      //         " " +
      //         cx({
      //           [classes.collapseItemTextMini]:
      //             this.props.miniActive && this.state.miniActive,
      //           [classes.collapseItemTextMiniRTL]:
      //             rtlActive && this.props.miniActive && this.state.miniActive,
      //           [classes.collapseItemTextRTL]: rtlActive
      //         });
      //       const itemIcon =
      //         classes.itemIcon +
      //         " " +
      //         cx({
      //           [classes.itemIconRTL]: rtlActive
      //         });
      //       const caret =
      //         classes.caret +
      //         " " +
      //         cx({
      //           [classes.caretRTL]: rtlActive
      //         });
      //       return (
      //         <ListItem key={key} className={classes.item}>
      //           <span
      //             className={navLinkClasses + ' collapsible'}
      //             onClick={() => this.openCollapse(prop.state)}
      //           >
      //             {prop.icon && (
      //               <ListItemIcon className={itemIcon}>
      //                 <prop.icon />
      //               </ListItemIcon>
      //             )}
      //             <ListItemText
      //               primary={prop.name}
      //               secondary={
      //                 <b
      //                   className={
      //                     caret +
      //                     " " +
      //                     (this.state[prop.state] ? classes.caretActive : "")
      //                   }
      //                 />
      //               }
      //               disableTypography={true}
      //               className={itemText}
      //             />
      //           </span>
      //           <Collapse in={this.state[prop.state]} unmountOnExit>
      //             <List className={mergeClasses(classes.list, classes.collapseList)}>
      //               {prop.views.map((prop, key) => {
      //                 if (prop.redirect) {
      //                   return null;
      //                 }
      //                 const navLinkClasses =
      //                   classes.collapseItemLink +
      //                   " " +
      //                   cx({
      //                     [" " + classes[color]]: this.activeRoute(prop.path)
      //                   });
      //                 const collapseItemMini =
      //                   classes.collapseItemMini +
      //                   " " +
      //                   cx({
      //                     [classes.collapseItemMiniRTL]: rtlActive
      //                   });
      //                 return (
      //                   <ListItem key={key} className={classes.collapseItem}>
      //                     <Link to={prop.path} className={navLinkClasses}>
      //                       <span className={collapseItemMini}>
      //                         {prop.mini}
      //                       </span>
      //                       <ListItemText
      //                         primary={prop.name}
      //                         disableTypography={true}
      //                         className={collapseItemText}
      //                       />
      //                     </Link>
      //                   </ListItem>
      //                 );
      //               })}
      //             </List>
      //           </Collapse>
      //         </ListItem>
      //       );
      //     }
      //     const navLinkClasses =
      //       classes.itemLink +
      //       " " +
      //       cx({
      //         [" " + classes[color]]: this.activeRoute(prop.path)
      //       });
      //     const itemText =
      //       classes.itemText +
      //       " " +
      //       cx({
      //         [classes.itemTextMini]:
      //           this.props.miniActive && this.state.miniActive,
      //         [classes.itemTextMiniRTL]:
      //           rtlActive && this.props.miniActive && this.state.miniActive,
      //         [classes.itemTextRTL]: rtlActive
      //       });
      //     const itemIcon =
      //       classes.itemIcon +
      //       " " +
      //       cx({
      //         [classes.itemIconRTL]: rtlActive
      //       });
      //     return (
      //       <ListItem key={key} className={classes.item}>
      //         <Link to={prop.path} className={navLinkClasses}>
      //           {prop.icon && (
      //             <ListItemIcon className={itemIcon}>
      //               <prop.icon />
      //             </ListItemIcon>
      //           )}
      //           <ListItemText
      //             primary={prop.title || prop.name}
      //             disableTypography={true}
      //             className={itemText}
      //           />
      //         </Link>
      //       </ListItem>
      //     );
      //   })}
      // </List>
    );

    // const logoNormal =
    //   classes.logoNormal +
    //   " " +
    //   cx({
    //     [classes.logoNormalSidebarMini]:
    //       this.props.miniActive && this.state.miniActive,
    //     [classes.logoNormalSidebarMiniRTL]:
    //       rtlActive && this.props.miniActive && this.state.miniActive,
    //     [classes.logoNormalRTL]: rtlActive
    //   });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    var brand = (
      <div className={logoClasses} style={{ minHeight: '50px' }}>
        <GatsbyLink to="/" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} style={{ width: '100px' }} />
        </GatsbyLink>
        {/* <GatsbyLink to="/" className={logoMini}>
          {logoText}
        </GatsbyLink> */}
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: mergeClasses(drawerPaper, classes[bgColor + "Background"])
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(Sidebar);
