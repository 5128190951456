import React, { useContext } from 'react';
import { navigate } from '@reach/router'; 
import { Formik, Form, FieldArray } from 'formik';
import { object, string } from 'yup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';

import EnhancedField from 'siteComponents/EnhancedField';

import './OpenEscrowForm.scss';

import { SnackbarContext } from 'contexts/SnackbarContext';
import { FirebaseContext } from 'contexts/FirebaseContext';
import { ModalContext } from 'contexts/ModalContext';

import SendForm from 'appComponents/SendForm';

import { filterByKeys } from 'utilities/form-helpers.ts';

const property = object().shape({
  address: string().required('Please enter the subject property address.'),
  // city: string(),
  // state: string(),
  // zip: string(),
  // // contractDate: date(),
  // // closingDate: date()
  // contractDate: string(),
  // closingDate: string()
});

// const buyer = object().shape({
//   name: string()
// });

// const seller = object().shape({
//   name: string(),
//   address: string()
// });

const schema = object().shape({
  property,
  // buyers: array().of(buyer),
  // sellers: array().of(seller)
});

export default function OpenEscrowForm({ id, profile, record, selectedRoles, type, inputs }) {
  const { openSnackbar } = useContext(SnackbarContext);
  const { openModal } = useContext(ModalContext);
  const { db, user } = useContext(FirebaseContext);

  // this creates an empty object for individual sections
  const createEmpty = sectionName => inputs
    .find(section => section.name === sectionName)
    .fields
    .reduce((acc, { name, value }) => ({
      ...acc,
      [name]: value || ''
    }), {});

  // this creates the entire object for the complete form
  const createInitialValues = shape => shape.reduce((acc, { name, type }) => {
    const data = createEmpty(name);

    return {
      ...acc,
      [name]: (type === 'single' ? data : [data])
    };
  }, {})

  let initialValues = null;

  if(!id) {
    initialValues = createInitialValues(inputs);
  }

  if(selectedRoles && profile) {
    // TODO: sanitize user inputs

    const { displayName: name, email } = user;

    Object.keys(selectedRoles).forEach(role => {
      if(!selectedRoles[role]) return;

      const section = inputs.find(({ name }) => name === role);
      const values = {
        ...createEmpty(section.name),
        ...filterByKeys(section.fields.map(({ name }) => name))({
          name,
          email,
          ...profile
        })
      };

      if(section.type === 'single') {
        // apply the profile attributes to the single one
        initialValues[section.name] = values;
      } else {
        // apply the profile attributes to the first one
        initialValues[section.name][0] = values;
      }
    });

    // if(selectedRoles.listing) {
    //   const broker = createEmpty('listingBrokers');

    //   broker.name = name;
    //   broker.email = email;
    //   applyUserProfile(broker, profile);

    //   initialValues.listingBrokers[0] = broker;
    // }

    // if(selectedRoles.selling) {
    //   const broker = createEmpty('sellingBrokers');

    //   broker.name = name;
    //   broker.email = email;
    //   applyUserProfile(broker, profile);

    //   initialValues.sellingBrokers[0] = broker;
    // }
  }
  
  function shareRecord() {
    openModal('Send to up to 5 recipients', <SendForm
      endpoint={`/users/open-escrows/${type}/${id}/email`}
      id={id}
      type="openEscrows"
    />);
  }

  function handleSuccess() {
    openSnackbar('Record successfully saved!', 'success');
  }

  async function handleSave(data, actions) {
    const timestamp = Date.now();

    const collection = type + 'OpenEscrows';

    try {

      if(record) {
      
        await db
          .collection('users')
          .doc(user.uid)
          .collection(collection)
          .doc(id)
          .set({
            updatedAt: timestamp,
            ...data
          }, { merge: true });
        
        handleSuccess();
  
      } else {

        const doc = await db
          .collection('users')
          .doc(user.uid)
          .collection(collection)
          .add({
            createdAt: timestamp,
            updatedAt: timestamp,
            ...data
          });
          
        handleSuccess();
  
        navigate(`/app/open-escrows/${type}/` + doc.id);
  
      }

    } catch(e) {

      openSnackbar(e.message, 'error');

    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues || record}
      onSubmit={handleSave}
      validationSchema={schema}
      render={props => (
        <Form className="form">
          {inputs.map((section, i) => (
            <Card
              key={section.name}
              className={`sections${section.span ? ' span' : ''}`}
            >
              <Typography variant="h5" className='center'>{section.label}</Typography>
              {section.type === 'single' ? section.fields.map((field, j) => (
                <EnhancedField
                  key={j}
                  name={section.name + '.' + field.name}
                  label={field.label}
                  type={field.type || 'text'}
                  display={['extraWide', 'horizontal']}
                  autoFocus={false || field.autofocus}
                  required={false || field.required}
                  options={field.options || null}
                  fullWidth={field.fullWidth || false}
                />
              )) : (
                <FieldArray
                  name={section.name}
                  render={({ push, remove }) => (
                    <div className="multi-outer">
                      <div className="multi-container">
                        {typeof props.values[section.name] !== 'undefined' && props.values[section.name].map((_, j) => (
                          <Card key={j} className="single-box">
                            <div className="header">
                              <Typography
                                variant="subtitle1"
                                style={{ margin: '0' }}
                              >
                                {section.singular} {j + 1}
                              </Typography>
                              {props.values[section.name].length > 1 && (
                                <Fab
                                  color="secondary"
                                  size="small"
                                  aria-label="remove"
                                  onClick={() => remove(j)}
                                >
                                  <RemoveIcon />
                                </Fab>
                              )}
                            </div>
                            {inputs[i].fields.map((field, k) => (
                              <EnhancedField
                                key={k}
                                className="constrainedFormControl"
                                name={section.name + '[' + j + '].' + field.name}
                                label={field.label}
                                type={field.type || 'text'}
                                display={['extraWide', 'horizontal']}
                                options={field.options || null}
                                fullWidth={field.fullWidth || false}
                              />
                            ))}
                          </Card>
                        ))}
                      </div>
                      <div className="add-box" style={{ height: (!props.values[section.name].length ? '150px' : '100px')}}>
                        <Fab
                          color="secondary"
                          size="small"
                          aria-label="add"
                          onClick={() => push(createEmpty(section.name))}
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </div>
                  )}
                />
              )}
            </Card>
          ))}
          <div className="submit-container">
            {props.isSubmitting ? (
              <CircularProgress/>
            ) : (
              <Button
                disabled={!props.dirty}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            )}
            {/* <Button
              disabled={!props.dirty}
              type="button"
              onClick={() => console.log('clicked download option')}
            >
              Download
            </Button> */}
            <Button
              disabled={!record}
              type="button"
              onClick={shareRecord}
            >
              Email
            </Button>
          </div>
        </Form>
      )}
    />
  );
}