import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Clearfix from 'components/Clearfix/Clearfix';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';

// core components
import CardIcon from 'components/Card/CardIcon';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { SnackbarContext } from 'contexts/SnackbarContext';

import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles';

import EnhancedField from 'siteComponents/EnhancedField';

import { filterObjectByKeys } from 'utilities/functions.tsx';

import styles from './Profile.module.css';

const validationSchema = object().shape({
  name: string().required('Your name is required.'),
  email: string()
    .email('Please enter a valid email address.')
    .required('Your email address is required.'),
  phone: string(),
  company: string(),
  address: string(),
  city: string(),
  state: string(),
  zip: string(),
  officePhone: string(),
  agentLicense: string(),
  brokerageLicense: string()
});

const shape = [
  {
    name: 'name',
    label: 'Name'
  },
  // TODO: check with Morris about email change handling
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'phone',
    label: 'Cell Phone'
  },
  {
    name: 'company',
    label: 'Company'
  },
  {
    name: 'address',
    label: 'Address'
  },
  {
    name: 'city',
    label: 'City'
  },
  {
    name: 'state',
    label: 'State',
    horizontal: true
  },
  {
    name: 'zip',
    label: 'Zip',
    horizontal: true
  },
  {
    name: 'officePhone',
    label: 'Office Phone'
  },
  {
    name: 'agentLicense',
    label: 'Agent Professional License Number'
  },
  {
    name: 'brokerageLicense',
    label: 'Brokerage Professional License Number'
  }
];

export default withStyles(userProfileStyles)(({ classes }) => {
  const { user, db } = useContext(FirebaseContext);
  const { openSnackbar } = useContext(SnackbarContext);

  const [initialValues, setInitialValues] = useState(null);
  const [profileExists, setProfileExists] = useState(null);

  useEffect(() => {
    db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(doc => {
        const userObj = {
          name: user.displayName,
          email: user.email
        };

        if(doc.exists) {
          setInitialValues({
            ...userObj,
            ...filterObjectByKeys(
              doc.data(),
              'phone',
              'company',
              'city',
              'state',
              'zip',
              'officePhone',
              'agentLicense',
              'brokerageLicense'
            )
          });
        } else {
          setInitialValues({
            ...userObj,
            phone: '',
            company: '',
            city: '',
            state: '',
            zip: '',
            officePhone: '',
            agentLicense: '',
            brokerageLicense: ''
          });
        }

        setProfileExists(doc.exists);
      });
    }, []);

  async function handleSubmit(values, actions) {

    try {

      const timestamp = Date.now();

      const updateObj = filterObjectByKeys(
        values,
        'phone',
        'company',
        'address',
        'city',
        'state',
        'zip',
        'officePhone',
        'agentLicense',
        'brokerageLicense'
      );

      if(!profileExists) {
        updateObj.createdAt = timestamp;
      }

      const promises = [
        db
          .collection('users')
          .doc(user.uid)
          .set({
            ...updateObj,
            updatedAt: timestamp
          }, { merge: true })
      ];

      if(values.name !== user.displayName) {
        // update the auth display name
        promises.push(user.updateProfile({ displayName: values.name }));
      }

      if(values.email !== user.email) {
        // update the user's email
        promises.push(user.updateEmail(values.email));
      }

      await Promise.all(promises);

      openSnackbar('Profile successfully updated!', 'success');

    } catch(e) {

      openSnackbar(e.message, 'error');

    }

    actions.setSubmitting(false);
  }

  if(profileExists === null) {
    return (<CircularProgress/>);
  }

  return (
    <div className={styles.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Edit Profile - <small>Complete your profile</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                render={({ isSubmitting }) => (
                  <Form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={5}>
                        {shape.map(({ label, name, horizontal }, i) => (
                          <EnhancedField
                            key={name}
                            name={name}
                            label={label}
                            autoFocus={i === 0}
                            styles={{
                              // display: 'block',
                              marginBottom: '10px',
                              width: horizontal ? '120px' : '350px'
                            }}
                          />
                        ))}
                      </GridItem>
                    </GridContainer>
                    {isSubmitting ? (
                      <CircularProgress/>
                    ) : (
                      <Button color="rose" type="submit" className={classes.updateProfileButton}>
                        Update Profile
                      </Button>
                    )}
                  </Form>
                )}
              />
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
})