import React from 'react';
import { Router } from '@reach/router';

import { FirebaseConsumer } from '../contexts/FirebaseContext';

import Auth from './auth/Auth';
import Login from './auth/login/Login';
import SignUp from './auth/sign-up/SignUp';
import Profile from './profile/Profile';
import Billing from './billing/Billing';
import Contacts from './contacts/Contacts';
import Settings from './settings/Settings';
import EmailMarketing from './email-marketing/EmailMarketing';

import Purchase from './open-escrows/purchase';
import PurchaseDefault from './open-escrows/purchase/PurchaseDefault';
import PurchaseNew from './open-escrows/purchase/PurchaseNew';
import PurchaseExisting from './open-escrows/purchase/PurchaseExisting';

import Refinance from './open-escrows/refinance';
import RefinanceDefault from './open-escrows/refinance/RefinanceDefault';
import RefinanceExisting from './open-escrows/refinance/RefinanceExisting';
import RefinanceNew from './open-escrows/refinance/RefinanceNew';

import NotFound from './404/NotFound';

import ProtectedRoute from 'appComponents/ProtectedRoute';
import Dashboard from 'appComponents/Dashboard';

export default () => (
  <FirebaseConsumer>
    {({ auth, db, user }) => (
      <Router basepath="/app">
        <Auth path="auth" user={user}>
          <Login
            path="login"
            auth={auth}
          />
          <SignUp
            path="sign-up"
            auth={auth}
          />
        </Auth>
        <ProtectedRoute path="/" component={Dashboard}>
          <Billing path="billing"/>
          <Contacts path="contacts/*"/>
          <Settings path="settings" />
          <EmailMarketing path="email-marketing/*"/>
          <Profile
            path="profile"
            db={db}
            user={user}
          />
          <Purchase
            path="open-escrows/purchase"
            db={db}
            user={user}
          >
            <PurchaseDefault
              path="/"
              db={db}
              user={user}
            />
            <PurchaseExisting
              path=":id"
              db={db}
              user={user}
            />
            <PurchaseNew
              path="new"
              db={db}
              user={user}
            />
          </Purchase>
          <Refinance
            path="open-escrows/refinance"
            db={db}
            user={user}
          >
            <RefinanceDefault
              path="/"
              db={db}
              user={user}
            />
            <RefinanceExisting
              path=":id"
              db={db}
              user={user}
            />
            <RefinanceNew
              path="new"
              db={db}
              user={user}
            />
          </Refinance>
          <NotFound default />
        </ProtectedRoute>
        <NotFound default />
      </Router>
    )}
  </FirebaseConsumer>
)