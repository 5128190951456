import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import GatsbyLink from 'gatsby-link';
import NavLink from '../components/NavLink';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import Menu from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import LockOpen from "@material-ui/icons/LockOpen";

// core components
import Button from "components/CustomButtons/Button";

import authNavbarStyle from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle";

import Logo from '../../assets/images/logo-white.svg';

const pagesRoutes = [
  // {
  //   name: 'Dashboard',
  //   short: 'Dashboard',
  //   mini: 'D',
  //   path: '/app',
  //   icon: Dashboard
  // },
  {
    name: 'Sign Up',
    short: 'Sign Up',
    mini: 'SU',
    path: '/app/auth/sign-up',
    icon: PersonAdd
  },
  {
    name: 'Login',
    short: 'Login',
    mini: 'L',
    path: '/app/auth/login',
    icon: LockOpen
  }
]

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.setState({open: false});
  //   }
  // }
  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    var list = (
      <List className={classes.list}>
        {/* <ListItem className={classes.listItem}>
          <Link to="/app/dashboard" className={classes.navLink}>
            <ListItemIcon className={classes.listItemIcon}>
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={"Dashboard"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </Link>
        </ListItem> */}
        {pagesRoutes.map((prop, key) => (
          <ListItem key={key} className={classes.listItem}>
            <NavLink activeClass={classes.navLinkActive} to={prop.path} className={classes.navLink}>
              <ListItemIcon className={classes.listItemIcon}>
                <prop.icon />
              </ListItemIcon>
              <ListItemText
                primary={prop.short}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        ))}
      </List>
    );
    return (
      <AppBar className={classes.appBar + appBarClasses} style={{ position: 'relative' }}>
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={classes.flex}>
              <GatsbyLink to="/">
                <Button className={classes.title} color="transparent">
                  <img src={Logo} alt="Replaix logo" height="25"/>
                </Button>
              </GatsbyLink>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <Button href="/" className={classes.title} color="transparent">
                <img src={Logo} alt="Replaix logo" height="25"/>
              </Button>
            </div>
          </Hidden>
          <Hidden smDown implementation="css">
            {list}
          </Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(authNavbarStyle)(PagesHeader);