import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Reference, Popper } from "react-popper";
// import { Link } from '@reach/router';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Paper from "@material-ui/core/Paper";
// import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
// import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// import SettingsOutlined from '@material-ui/icons/SettingsOutlined';

// core components
// import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";

import { FirebaseContext } from '../contexts/FirebaseContext';

class HeaderLinks extends React.Component {
  state = {
    open: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes, rtlActive } = this.props;
    // const { open } = this.state;
    // const searchButton =
    //   classes.top +
    //   " " +
    //   classes.searchButton +
    //   " " +
    //   classNames({
    //     [classes.searchRTL]: rtlActive
    //   });
    // const dropdownItem =
    //   classes.dropdownItem +
    //   " " +
    //   classNames({
    //     [classes.dropdownItemRTL]: rtlActive
    //   });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    // const managerClasses = classNames({
    //   [classes.managerClasses]: true
    // });
    return (
      <FirebaseContext.Consumer>
        {({ auth }) => (
          <div className={wrapper}>
            {/* <CustomInput
              rtlActive={rtlActive}
              formControlProps={{
                className: classes.top + " " + classes.search
              }}
              inputProps={{
                placeholder: 'Search',
                inputProps: {
                  "aria-label": 'Search',
                  className: classes.searchInput
                }
              }}
            /> */}
            {/* <Button
              color="white"
              aria-label="edit"
              justIcon
              round
              className={searchButton}
            >
              <Search
                className={classes.headerLinksSvg + " " + classes.searchIcon}
              />
            </Button> */}
            {/* <Manager className={managerClasses}>
              <Reference>
                {({ ref }) => (
                  <Button
                    color="transparent"
                    justIcon
                    aria-label="Notifications"
                    aria-owns={open ? "menu-list" : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                      label: rtlActive ? classes.labelRTL : ""
                    }}
                    ref={ref}
                  >
                    <Notifications
                      className={
                        classes.headerLinksSvg +
                        " " +
                        (rtlActive
                          ? classes.links + " " + classes.linksRTL
                          : classes.links)
                      }
                    />
                    <span className={classes.notifications}>5</span>
                    <Hidden mdUp>
                      <span onClick={this.handleClick} className={classes.linkText}>
                        {rtlActive ? "إعلام" : "Notification"}
                      </span>
                    </Hidden>
                  </Button>
                )}
              </Reference>
              <Popper
                placement="bottom-start"
                eventsEnabled={open}
                className={
                  classNames({ [classes.popperClose]: !open }) +
                  " " +
                  classes.pooperResponsive
                }
              >
                <ClickAwayListener onClickAway={this.handleClose}>
                  <Grow
                    in={open}
                    id="menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper className={classes.dropdown}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={this.handleClose}
                          className={dropdownItem}
                        >
                          {rtlActive
                            ? "إجلاء أوزار الأسيوي حين بل, كما"
                            : "Mike John responded to your email"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleClose}
                          className={dropdownItem}
                        >
                          {rtlActive
                            ? "شعار إعلان الأرضية قد ذلك"
                            : "You have 5 new tasks"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleClose}
                          className={dropdownItem}
                        >
                          {rtlActive
                            ? "ثمّة الخاصّة و على. مع جيما"
                            : "You're now friend with Andrew"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleClose}
                          className={dropdownItem}
                        >
                          {rtlActive ? "قد علاقة" : "Another Notification"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleClose}
                          className={dropdownItem}
                        >
                          {rtlActive ? "قد فاتّبع" : "Another One"}
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </Grow>
                </ClickAwayListener>
              </Popper>
            </Manager> */}
            {/* <Link to="profile" className={}>
              <Button
                color="transparent"
                aria-label="Person"
                justIcon
                className={classes.buttonLink}
              >
                <Person
                  className={
                    classes.headerLinksSvg +
                    " " +
                    classes.links
                  }
                />
                <Hidden mdUp>
                  <span className={classes.linkText}>
                    Profile
                  </span>
                </Hidden>
              </Button>
            </Link> */}
            {/* <Link to="settings">
              <Button
                color="transparent"
                simple
                aria-label="Outlined"
                justIcon
                className={classes.buttonLink}
              >
                <SettingsOutlined
                  className={
                    classes.headerLinksSvg +
                    " " +
                    classes.links
                  }
                />
                <Hidden mdUp>
                  <span className={classes.linkText}>
                    Settings
                  </span>
                </Hidden>
              </Button>
            </Link> */}
            <Hidden smDown>
              <Button
                color="transparent"
                aria-label="Sign Out"
                onClick={() => auth.signOut()}
              >
                Sign Out
              </Button>
            </Hidden>
          </div>
        )}
      </FirebaseContext.Consumer>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);