import React from 'react';

import { Link } from '@reach/router';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ContactsTable from './ContactsTable';
import DeleteContactsForm from './DeleteContactsForm';
import NewContactForm from './NewContactForm';
import PageWrapper from 'appComponents/PageWrapper';

import styles from './common.module.css';

export default ({ contacts, db, user }) => {
  const remainingContacts = process.env.GATSBY_CONTACTS_LIMIT - contacts.length;
  
  return (
    <>
      <Paper className={styles.container}>
        {remainingContacts > 0 && (
          <Button
            to="./upload"
            component={Link}
            variant="contained"
            color="primary"
          >
            Upload Contacts
          </Button>
        )}
        {contacts.length > 0 && (
          <DeleteContactsForm/>
        )}
      </Paper>
      {contacts.length ? (
        <ContactsTable contacts={contacts}/>
      ) : (
        <PageWrapper>
          <Typography variant="body1">Your contacts list is currently empty</Typography>
        </PageWrapper>
      )}
      {remainingContacts > 0 && (
        <Paper className={styles.container}>
          <Typography variant="h4">Add a Contact</Typography>
          <NewContactForm db={db} userId={user.uid}/>
        </Paper>
      )}
    </>
  );
}