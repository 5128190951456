import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import PaymentMethod from './PaymentMethod';
import PageWrapper from 'appComponents/PageWrapper';
import Stepper from './Stepper';
import Subscription from './Subscription';

import * as braintreeClient from 'braintree-web/client';

import { sendApiRequest } from 'utilities/api';
import { useFirebase, useSnackbar } from 'utilities/hooks';

export default () => {
  const [client, setClient] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const { user } = useFirebase();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    async function load() {
      try {
        // const { docs } = await db
        //   .collection('users')
        //   .doc(user.uid)
        //   .collection('subscriptions')
        //   .get();

        // const planId = docs.length
        //   ? docs[0].data.id
        //   : null;

        const {
          token,
          customer: returnedCustomer,
          subscription: returnedSubscription
        } = await sendApiRequest('/users/billing', user);
  
        setCustomer(returnedCustomer);
        setSubscription(returnedSubscription);

        const instance = await braintreeClient.create({ authorization: token });
  
        setClient(instance);
  
        return instance;

      } catch(e) {
        console.log(e)
        openSnackbar('Something went wrong. Please refresh the page.', 'error');

      }
    }

    const instancePromise = load();

    return () => Promise
      .resolve(instancePromise)
      .then(inst => inst.teardown());
  }, []);

  let inner;

  if(client === null) {
    inner = <CircularProgress/>;
  } else if(!customer || !customer.paymentMethods.length || !subscription) {
    inner = (
      <Stepper
        client={client}
        customer={customer}
        setCustomer={setCustomer}
        subscription={subscription}
        setSubscription={setSubscription}
      />
    );
  } else {
    inner = (
      <Grid container>
        <Grid item md={6}>
          <PaymentMethod
            client={client}
            customer={customer}
            setCustomer={setCustomer}
            subscription={subscription}
          />
        </Grid>
        <Grid item md={6}>
          <Subscription
            customer={customer}
            subscription={subscription}
            setSubscription={setSubscription}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>Billing</title>
        {/* <script src="https://www.paypalobjects.com/api/checkout.js" data-version-4 log-level="warn"></script> */}
      </Helmet>
      {inner}
    </PageWrapper>
  );
}