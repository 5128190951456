import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const options = [
  {
    label: '$15/month billed annually',
    value: 'yearly'
  },
  {
    label: '$20/month billed month to month',
    value: 'monthly'
  }
];

export default ({ selectedPlan, setSelectedPlan }) => {
  return (
    <RadioGroup
      aria-label="Subscription"
      name="subscription"
      value={selectedPlan}
      onChange={e => setSelectedPlan(e.target.value)}
    >
      {options.map(option => (
        <FormControlLabel
          key={option.label}
          {...option}
          control={<Radio/>}
        />
      ))}
    </RadioGroup>
  )
}