import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';

import ContactsDefault from './default/ContactsDefault';
import SingleContact from './single/SingleContact';
import ContactsUpload from './upload/ContactsUpload';

import { useFirebase } from 'utilities/hooks';

export default () => {
  const { user, db } = useFirebase();

  const [contacts, setContacts] = useState(null);

  useEffect(() => setTimeout(
    () => setContacts(curr => curr === null ? [] : curr),
    1000
  ), []);

  useEffect(() => {
    const collectionRef =  db
      .collection('users')
      .doc(user.uid)
      .collection('contacts')
      .orderBy('firstName', 'asc');

    const unsubscribe = collectionRef.onSnapshot(snap => {
      // setContacts(snap.docs);
      const toBeAdded = [];

      snap.docChanges().forEach(({ type, doc }) => {
        if(type === 'added') {
          toBeAdded.push(doc);
        } else {
          setContacts(current => {
            const index = current.findIndex(contact => contact.id === doc.id);

            if(index >= 0) {
              return current.slice(0, index).concat(type === 'modified' ? [doc] : [], current.slice(index + 1));
            }

            return current;
          });
        }
      });

      if(toBeAdded.length) {
        setContacts(current => current === null ? toBeAdded : current.concat(toBeAdded));
      }
    });

    return unsubscribe;
  }, []);

  return (
    <>
      <Helmet title="Contacts"/>
      {contacts === null ? (<CircularProgress/>) : (
        <Router primary={false}>
          <ContactsUpload
            path="upload"
            contacts={contacts}
          />
          <SingleContact
            path=":id"
            contacts={contacts}
            user={user}
            db={db}
          />
          <ContactsDefault
            path="/"
            contacts={contacts}
            user={user}
            db={db}
          />
        </Router>
      )}
    </>
  )
}