import React from 'react';

import { FastField, getIn } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import nanoid from 'nanoid';

import fieldStyles from './EnhancedField.module.css';

const hasError = (form, name) => typeof getIn(form.errors, name) !== 'undefined'
  && typeof getIn(form.touched, name) !== 'undefined'

export default ({
  name,
  label = null,
  className,
  styles,
  display,
  multiline,
  rows,
  inputProps,
  fullWidth = false,
  ...rest
}) => {
  const id = nanoid();

  return (
    <FastField
      name={name}
      render={({ field, form }) => {
        const error = hasError(form, name);
        
        if(rest.type === 'radio') {
          return (
            <FormControl
              className={className ? fieldStyles[className] : null}
              style={styles || {}}
              fullWidth={fullWidth}
              component="fieldset"
            >
              {label && (<FormLabel component="legend">{label}</FormLabel>)}
              <RadioGroup
                {...rest}
                {...field}
                className={display ? display.map(single => fieldStyles[single]).join(' ') : null}
              >
                {rest.options.map(option => {
                  const [key, val] = typeof option === 'string'
                    ? [option, option]
                    : [option.value, option.label];
                    // console.log(key, val)
                  return (
                    <FormControlLabel
                      key={key}
                      value={val}
                      control={<Radio/>}
                      label={option}
                    />
                  );
                })}
              </RadioGroup>
              {error && (
                <FormHelperText
                  error={true}
                >
                  {getIn(form.errors, name).indexOf('must be a `date` type, but the final value was: `Invalid Date`') > -1 ? 'Please enter a valid date.' : getIn(form.errors, name)}
                </FormHelperText>
              )}
            </FormControl>
          )
        }

        let innerComponent;

        switch(rest.type) {
          case 'select':
            innerComponent = (
              <Select
                {...rest}
                {...field}
                error={error}
                className={display ? display.map(single => fieldStyles[single]).join(' ') : null}
              >
                <MenuItem value="">Select One</MenuItem>
                {rest.options.map(option => {
                  if(typeof option === 'string') {
                    return (<MenuItem value={option} key={option}>{option}</MenuItem>);
                  }

                  return <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                })}
              </Select>
            );
            break;
          case 'radio':
            innerComponent = (
              <RadioGroup
                {...rest}
                {...field}
                error={error}
                className={display ? display.map(single => fieldStyles[single]).join(' ') : null}
              >
                {rest.options.map(option => (
                  <FormControlLabel
                    value={option}
                    key={option}
                    control={<Radio/>}
                    label={option}
                  />
                ))}
              </RadioGroup>
            );
            break;
          case 'textarea':
            return (
              <TextField 
                {...rest}
                {...field}
                fullWidth={fullWidth}
                type="textarea" 
                multiline={true}
                rowsMax="5"
                rows="5"
              />
            );
          default:
            innerComponent = (
              <Input
                {...rest}
                {...field}
                id={id}
                // label={}
                // helperText={error ? getIn(form.errors, name) : null}
                error={error}
                className={display ? display.map(single => fieldStyles[single]).join(' ') : null}
                multiline={multiline || false}
                rows={rows || 1}
                style={styles || {}}
                {...inputProps}
              />
            );
        }

        return (
          <FormControl
            className={className ? fieldStyles[className] : null}
            style={styles || {}}
            fullWidth={fullWidth}
          >
            <InputLabel
              // className={classes.labelRoot + " " + labelClasses}
              htmlFor={id}
            >
              {label}
              {rest.required && (<> <span className="required">*</span></>)}
            </InputLabel>
            {innerComponent}
            {error && (
              <FormHelperText
                error={true}
              >
                {getIn(form.errors, name).indexOf('must be a `date` type, but the final value was: `Invalid Date`') > -1 ? 'Please enter a valid date.' : getIn(form.errors, name)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}