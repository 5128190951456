import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import styles from './Drilldown.module.scss';

export default class Drilldown extends Component {
  constructor(props) {
    super(props);

    // defined event listener here so when we can remove the same listener (scoping)
    this.clickListener = e => {
      if(this.props.open) {
        this.props.closeDrilldown();
      }
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.clickListener);
  }
 
  componentWillUnmount() {
    window.removeEventListener('click', this.clickListener);
  }
  
  render() {
    const { open, items, type, path, render } = this.props;    

    return (
      <div className={styles.drilldown + (open ? ' ' + styles.drilldownOpen : '')}>
        {items.length ? (
          <List component="nav" style={{padding: '0'}}>
            {items.map((item, index) => render({
              id: item.id,
              index,
              path: path + '/' + item.id,
              ...item
            }))}
              
              {/* <ListItem
                key={item.id}
                // component={Link}
                // to={path + '/' + item.id}
                button
              >
                {render({
                  path: path + '/' + item.id,
                  ...item
                })}
              </ListItem> */}
            {/* ))} */}
          </List>
        ) : (
          <div style={{ padding: '15px' }}>
            <Typography variant="body1">To create a new {type}, click the + icon to the right.</Typography>
          </div>
        )}
      </div>
    );
  }
}