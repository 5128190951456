import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { pluralize } from 'utilities/functions';

export default ({ action, children, color, selected }) => {
  const { db, user } = useFirebase();
  const { openSnackbar } = useSnackbar();

  const [spinning, setSpinning] = useState(false);

  const contactsRef = db
    .collection('users')
    .doc(user.uid)
    .collection('contacts');

  async function handleClick() {
    setSpinning(true);

    try {

      let promises;

      if(action === 'enable') {
        promises = selected
          .map(id => contactsRef.doc(id).set({
            active: true,
            updatedAt: Date.now()
          }, { merge: true }));
      } else if (action === 'disable') {
        promises = selected
          .map(id => contactsRef.doc(id).set({
            active: false,
            updatedAt: Date.now()
          }, { merge: true }));
      } else {
        promises = selected
          .map(id => contactsRef.doc(id).delete());
      }

      await Promise.all(promises);

      openSnackbar(
        `${selected.length} ${pluralize('contact', selected.length)} ${action}d!`,
        'success'
      );

    } catch(e) {
      openSnackbar(e.message, 'error');
    }

    setSpinning(false);
  }

  return (
    <Button
      variant="contained"
      color={color}
      disabled={spinning}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}