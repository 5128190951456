import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link, navigate } from '@reach/router';

import OpenEscrowForm from '../OpenEscrowForm';

import inputs from './refinance-inputs';

import styles from '../styles.module.css';

export default class RefinanceDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  async componentDidMount() {
    const query = await this.props.db
      .collection('users')
      .doc(this.props.user.uid)
      .collection('refinanceOpenEscrows')
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();
    
    if(query.docs.length) {
      const doc = query.docs[0];

      // history.replaceState({}, 'Open Escrows', '/app/open-escrows/refinance/' + doc.id); // eslint-disable-line
      // return this.setState({
      //   loading: false,
      //   record: {
      //     id: doc.id,
      //     ...doc.data()
      //   }
      // });
// TODO: instead of redirect, we should just change the url
      return navigate('/app/open-escrows/refinance/' + doc.id);
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, record } = this.state;

    if(loading) {
      return (<CircularProgress/>);
    }

    if(record) {
      return (
        <OpenEscrowForm
          id={record.id}
          record={record}
          inputs={inputs}
          type="refinance"
        />
      );
    }

    return (
      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'middle'
      }}>
        <Fab
          component={Link}
          to="/app/open-escrows/refinance/new"
          size="large"
          color="primary"
          className={styles.fab}
        >
          <AddIcon/>
        </Fab>
      </div>
    )
  }
}