import React, { useState } from 'react';

// import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Confirmation from './Confirmation';
import PaymentMethod from './PaymentMethod';
import SubscriptionForm from './SubscriptionForm';

import styles from './Stepper.module.scss';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '90%',
//   },
//   button: {
//     marginRight: theme.spacing(1),
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
// }));

export default ({ client, customer, setCustomer, setSubscription }) => {
  // const classes = useStyles();
  const classes = {};
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('yearly');

  try {
    const billingOption = localStorage.getItem('billingOption');
    if(billingOption === 'monthly') {
      setSelectedPlan('monthly');
    }
    localStorage.removeItem('billingOption');
  } catch(e) {

  }

  const steps = [
    {
      label: 'Select Subscription',
      component: SubscriptionForm,
      props: {
        selectedPlan,
        setSelectedPlan
      }
    },
    {
      label: 'Choose Payment Method',
      component: PaymentMethod,
      props: {
        client,
        customer,
        onSuccess: () => setActiveStep(2),
        setCustomer
      }
    },
    {
      label: 'Subscribe',
      component: Confirmation,
      props: {
        selectedPlan,
        customer,
        setSubscription
      }
    }
  ];

  function handleNext() {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }

    // setActiveStep(prevActiveStep => prevActiveStep + 1);
    // setSkipped(newSkipped);
    if(activeStep === 1) {
      console.log('here is where we need to submit the credit card form');
    }

    setActiveStep(step => step + 1);
  }

  function handleBack() {
    setActiveStep(step => step - 1);
  }

  const { component: Component, props } = steps[activeStep];

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map(({ label }) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Typography component="div" className={styles.body}>
            <Component {...props}/>
          </Typography>
          <div>
            {activeStep > 0 && (
              <Button
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
            )}
            {activeStep < steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={activeStep === 1 && (customer === null || customer.paymentMethods.length === 0)}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}