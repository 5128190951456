import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
// import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "../dashboard-pro-child/Header";
// import Footer from "../dashboard-pro-child/Footer";
import Sidebar from "../dashboard-pro-child/Sidebar";

// import dashboardRoutes from "routes/dashboard";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle";

// import image from "assets/img/sidebar-2.jpg";
import image from "../../assets/images/sidebar-background.jpg";
// import logo from "assets/img/logo-white.svg";
import logo from '../../assets/images/logo-white.svg';

// const switchRoutes = (
//   <Switch>
//     {dashboardRoutes.map((prop, key) => {
//       if (prop.redirect)
//         return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
//       if (prop.collapse)
//         return prop.views.map((prop, key) => {
//           return (
//             <Route path={prop.path} component={prop.component} key={key} />
//           );
//         });
//       return <Route path={prop.path} component={prop.component} key={key} />;
//     })}
//   </Switch>
// );

var ps;

const dashboardRoutes = [];

class Dashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  // getRoute() {
  //   return this.props.location.pathname !== "/maps/full-screen-maps";
  // }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if(this.state.mobileOpen){
  //       this.setState({mobileOpen: false})
  //     }
  //   }
  // }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          logoText="Replaix"
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {/* {this.getRoute() ? ( */}
            <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
              <div className={classes.container} style={{height: 'calc(100vh - 132px)'}}>
                <div style={{
                  height: '100%',
                  maxHeight: '100%'
                }}>
                  {rest.children}
                </div>
              </div>
            </div>
          {/* ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )} */}
          {/* <Footer fluid/> */}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);