import React, { useState } from 'react';

import { navigate } from '@reach/router';
import cronstrue from 'cronstrue';
import nanoid from 'nanoid';

import { Formik, Field, Form } from 'formik';
import { object, string, number, boolean } from 'yup';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import EnhancedField from 'siteComponents/EnhancedField';

import styles from './CronForm.module.css';

import { useSnackbar, useFirebase } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

const validationSchema = object().shape({
  name: string().required('Please specify a name.'),
  hour: number().required('Please specify an hour.'),
  minute: number().required('Please specify the minutes.'),
  type: string().required('Please specify AM or PM.'),
  days: object().shape({
    1: boolean(),
    2: boolean(),
    3: boolean(),
    4: boolean(),
    5: boolean(),
    6: boolean(),
    7: boolean()
  }),
  weeks: object().shape({
    1: boolean(),
    2: boolean(),
    3: boolean(),
    4: boolean()
  }),
  months: object().shape({
    1: boolean(),
    2: boolean(),
    3: boolean(),
    4: boolean(),
    5: boolean(),
    6: boolean(),
    7: boolean(),
    8: boolean(),
    9: boolean(),
    10: boolean(),
    11: boolean(),
    12: boolean(),
  })
});

const defaultValues = {
  name: '',
  hour: '',
  minute: '',
  type: '',
  days: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  },
  weeks: {
    1: false,
    2: false,
    3: false,
    4: false
  },
  months: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
  }
}

function getFriendlyHour(num) {
  return num === 0 ? 12 : num;
}

function getFriendlyMinute(num) {
  return num < 10 ? '0' + num.toString() : num;
}

function *generateOptions(max, transformFunc) {
  let current = 0;
  while(current < max) {
    yield {
      value: current,
      label: transformFunc(current)
    };
    current++;
  }
}

const hours = Array.from(generateOptions(12, getFriendlyHour));
const minutes = Array.from(generateOptions(60, getFriendlyMinute));

const days = [
  {
    name: 1,
    label: 'Mon'
  },
  {
    name: 2,
    label: 'Tue'
  },
  {
    name: 3,
    label: 'Wed'
  },
  {
    name: 4,
    label: 'Thu'
  },
  {
    name: 5,
    label: 'Fri'
  },
  {
    name: 6,
    label: 'Sat'
  },
  {
    name: 7,
    label: 'Sun'
  }
];

const weeks = [
  {
    name: 1,
    label: 'Week 1'
  },
  {
    name: 2,
    label: 'Week 2'
  },
  {
    name: 3,
    label: 'Week 3'
  },
  {
    name: 4,
    label: 'Week 4'
  }
];

const months = [
  {
    name: 1,
    label: 'Jan'
  },
  {
    name: 2,
    label: 'Feb'
  },
  {
    name: 3,
    label: 'Mar'
  },
  {
    name: 4,
    label: 'Apr'
  },
  {
    name: 5,
    label: 'May'
  },
  {
    name: 6,
    label: 'Jun'
  },
  {
    name: 7,
    label: 'Jul'
  },
  {
    name: 8,
    label: 'Aug'
  },
  {
    name: 9,
    label: 'Sep'
  },
  {
    name: 10,
    label: 'Oct'
  },
  {
    name: 11,
    label: 'Nov'
  },
  {
    name: 12,
    label: 'Dec'
  }
]

function ExclusiveToggleable({ name, label, sectionName, sectionValues }) {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        function handleClick() {
          if(!field.value) {
            Object
              .keys(sectionValues)
              .forEach(key => form.setFieldValue(sectionName + '.' + key, false));
          }
          form.setFieldValue(name, !field.value);
        }

        return (
          <Button
            variant={field.value ? 'contained' : 'outlined'}
            onClick={handleClick}
            color="secondary"
            // className={styles.toggleButton}
            style={{ margin: '0 25px 25px 0' }}
          >
            {label}
          </Button>
        );
      }}
    />
  );
}

function Toggleable({ name, label }) {
  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <Button
          variant={field.value ? 'contained' : 'outlined'}
          onClick={() => form.setFieldValue(name, !field.value)}
          color="secondary"
          // className={styles.toggleButton}
          style={{ margin: '0 25px 25px 0' }}
        >
          {label}
        </Button>
      )}
    />
  );
}

function ToggleAll({ label, section, sectionValues, setFieldValue }) {
  const id = nanoid();
  const checked = checkForAllTrue(sectionValues);

  function handleClick() {
    if(checked) {
      // turn everything off!
      Object
        .keys(sectionValues)
        .forEach(key => setFieldValue(section + '.' + key, false));
    } else {
      // turn everything on!
      Object
        .keys(sectionValues)
        .forEach(key => setFieldValue(section + '.' + key, true));
    }
  }

  return (
    <div className={styles.toggleAll}>
      <label className={styles.hover} htmlFor={id}>Every {label}</label>{' '}
      <input
        id={id}
        type="checkbox"
        className={styles.hover}
        checked={checked}
        onClick={handleClick}
        readOnly
      />
    </div>
  )
}

function checkForAll(condition) {
  return function(obj) {
    return Object
      .keys(obj)
      .every(key => obj[key] === condition);
  }
}

const checkForAllFalse = checkForAll(false);
const checkForAllTrue = checkForAll(true);

function summarizeObject(obj) {
  return Object
    .keys(obj)
    .filter(key => obj[key] === true)
    .join(',');
}

function getCronExpression(hour, minute, type, days, weeks, months) {
  const daysSuffix = checkForAllTrue(weeks)
    ? ''
    : '#' + summarizeObject(weeks);

  return [
    minute, // minutes
    type === 'AM' ? hour : hour + 12, // hours
    '*', // day of the month
    checkForAllTrue(months) ? '*' : summarizeObject(months), // months
    (checkForAllTrue(days) ? '*' : summarizeObject(days)) + daysSuffix // days of the week
  ].join(' ');
}

function *minMaxToArray(max, min) {
  let current = min || 1;

  while(current <= max) {
    yield current;
    current++;
  }
}

function dateStringToObject(str, maximum) {
  const arr = Array.from(minMaxToArray(maximum));
  const nums = str.split(',');

  if(nums === '*') {
    return arr.reduce((acc, curr) => ({
      ...acc,
      [curr]: true
    }), {});
  }

  const mapped = nums.map(num => +num);

  return arr.reduce((acc, curr) => ({
    ...acc,
    [curr]: mapped.includes(curr) ? true : false
  }), {});
}

function translateCronExpression(expression) {
  const [minute, hour, , months, days] = expression.split(' ');

  const [day, week] = days.split('#');

  return {
    minute,
    hour: hour > 11 ? hour - 12 : hour,
    type: hour > 11 ? 'PM' : 'AM',
    days: dateStringToObject(day, 7),
    weeks: dateStringToObject(typeof week === 'undefined' ? '*' : week, 4),
    months: dateStringToObject(months, 12)
  };
}

function parseCronSettings({ hour, minute, type, days, weeks, months }) {
  if(
    hour === ''
    || minute === ''
    || !type
    || checkForAllFalse(days)
    || checkForAllFalse(weeks)
    || checkForAllFalse(months)
  ) {
    return null;
  }

  const cronString = getCronExpression(hour, minute, type, days, weeks, months);

  return cronstrue.toString(cronString);
}

export default ({ id = null, data, setCrons }) => {
  const { completeAction } = useSnackbar();
  const { user } = useFirebase();

  const [initialValues] = useState(id === null ? defaultValues : {
    name: data.name,
    ...translateCronExpression(data.expression)
  });

  async function handleSubmit(values, actions) {
    // console.log(values);

    await completeAction(async() => {

      const { name, hour, minute, type, days, weeks, months } = values;
      const expression = getCronExpression(hour, minute, type, days, weeks, months);

      if(id) {
        
        const response = await sendApiRequest(
          '/users/crons/' + id,
          user,
          'put',
          { name, expression }
        );
  
        if(response.status !== 'success') {
          throw new Error('Something went wrong. Please try again.');
        }

        setCrons(crons => crons.map(cron => cron.id === id ? response.cronJob : cron));

      } else {
  
        // this is a new job
        const response = await sendApiRequest(
          '/users/crons',
          user,
          'post',
          { name, expression }
        );
  
        if(response.status !== 'success') {
          throw new Error('Something went wrong. Please try again.');
        }

        setCrons(crons => crons.concat([response.cronJob]));
      }

      actions.setSubmitting(false);
      navigate('/app/email-marketing');

      return 'Your scheduled job was successfully saved!';
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      render={({ isSubmitting, setFieldValue, values }) => {
        const summary = parseCronSettings(values);

        return (
          <>
            {id === null && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h3" className={styles.heading}>Add a Cron Job</Typography>
                </Grid>
              </Grid>
            )}
            <Grid container justify="center">
              <Grid item xs={12} md={9}>
                <Form>
                  <Typography variant="h3">{values.name || 'Untitled'}</Typography>
                  <Paper className={styles.summary}>
                    <Typography component="div" variant="body1">
                      {!summary ? (
                        <div className={styles.warning}>Please complete all the selections to continue.</div>
                      ) : (<div className={styles.complete}>{summary}.</div>)}
                    </Typography>
                  </Paper>
                  {/* <Typography variant="h4">Name</Typography> */}
                  <div className={styles.group}>
                    <EnhancedField
                      label="Name"
                      name="name"
                      key="name"
                      type="text"
                    />
                  </div>
                  <div className={styles.group}>
                    <Typography variant="h4" className={styles.heading2}>Time</Typography>
                    <EnhancedField
                      label="Hour"
                      name="hour"
                      key="hour"
                      type="select"
                      options={hours}
                      styles={{ marginRight: '25px', width: '75px' }}
                    />
                    <EnhancedField
                      label="Minute"
                      name="minute"
                      key="minute"
                      type="select"
                      options={minutes}
                      styles={{ marginRight: '25px', width: '75px' }}
                    />
                    <EnhancedField
                      label="AM/PM"
                      name="type"
                      key="type"
                      type="radio"
                      options={['AM', 'PM']}
                    />
                  </div>
                  <Grid container className={styles.group}>
                    <Grid item>
                      <Typography variant="h4" className={styles.heading2}>Day (pick one)</Typography>
                      {days.map(day => (
                        <ExclusiveToggleable
                          name={'days.' + day.name}
                          label={day.label}
                          sectionName="days"
                          sectionValues={values['days']}
                          key={day.name}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  <Grid container className={styles.group}>
                    <Grid item>
                      <Typography variant="h4" className={styles.heading2}>Week (select one or all)</Typography>
                      <ToggleAll
                        label="Week"
                        section="weeks"
                        sectionValues={values['weeks']}
                        setFieldValue={setFieldValue}
                      />
                      <Grid container>
                        {checkForAllTrue(values['weeks']) && (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              component="p"
                            >
                              You have selected all four weeks.
                            </Button>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          md={8}
                          style={{
                            display: checkForAllTrue(values['weeks']) ? 'none' : 'block'
                          }}
                        >
                          {weeks.map(week => (
                            <ExclusiveToggleable
                              name={'weeks.' + week.name}
                              label={week.label}
                              sectionName="weeks"
                              sectionValues={values['weeks']}
                              key={week.name}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={styles.group}>
                    <Grid item>
                      <Typography variant="h4" className={styles.heading2}>Month (select all that apply)</Typography>
                      <ToggleAll
                        label="Month"
                        section="months"
                        sectionValues={values['months']}
                        setFieldValue={setFieldValue}
                      />
                      {months.map(month => (
                        <Toggleable
                          name={'months.' + month.name}
                          label={month.label}
                          key={month.name}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  {isSubmitting ? (
                    <CircularProgress/>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  )}
                </Form>
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  )
}