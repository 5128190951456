import React from 'react';
import { Redirect } from '@reach/router';

import { useFirebase } from 'utilities/hooks';

export default ({ component: Component, ...rest }) => {
  const { user } = useFirebase();

  if(!user || !user.emailVerified) {
    try {
      localStorage.setItem('returnUrl', window.location.pathname);
    } catch(e) {

    }

    return (<Redirect noThrow to="/app/auth/login"/>);
  }

  return (<Component {...rest}/>);
}