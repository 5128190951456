import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import { object, string } from 'yup';

// @material-ui/core components
import Email from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";

// Dashboard Pro
import Button from "components/CustomButtons/Button";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import EnhancedField from 'siteComponents/EnhancedField';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { SnackbarContext } from 'contexts/SnackbarContext';

import styles from './Login.module.css';

const validationSchema = object().shape({
  email: string().email('Please enter a valid email address.').required('Please enter your email address.')
});

export default ({ classes, setShowForgotPassword }) => {
  const [submitting, setSubmitting] = useState(false);
  const { auth } = useContext(FirebaseContext);
  const { openSnackbar } = useContext(SnackbarContext);

  function handleSubmit({ email }) {
    setSubmitting(true);
    auth.sendPasswordResetEmail(email, {
      url: process.env.GATSBY_BASE_URL + '/app/auth/login',
      handleCodeInApp: true
    })
      .then(() => {
        setSubmitting(false);
        openSnackbar('Please check your email for the password reset link.', 'success');
      })
      .catch(e => {
        setSubmitting(false);
        openSnackbar(e.message, 'error');
      });
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={() => (
        <>
          <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="rose"
          >
            <h4 className={classes.cardTitle}>Forgot Password</h4>
          </CardHeader>
          <CardBody>
            <Form>
              <EnhancedField
                name="email"
                label="Email"
                fullWidth
                style={{ width: '300px' }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  inputProps: {'data-test': 'forgot-password-email'}
                }}
              />
              <CardFooter className={classes.justifyContentCenter}>
                {submitting ? (
                  <CircularProgress/>
                ) : (
                  <Button type="submit" color="rose" simple size="lg" block>
                    Send Link
                  </Button>
                )}
              </CardFooter>
            </Form>
            <div className={styles.forgotContainer}>
              <span className={styles.forgotLink} onClick={() => setShowForgotPassword(false)}>
                Remember your password? Log in here.
              </span>
            </div>
          </CardBody>
        </>
      )}
    />
  )
}