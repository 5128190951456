export async function sendApiRequest(endpoint, user, method, body) {
  const token = await user.getIdToken();

  const params = {
    method,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  };

  if(body) {
    params.body = JSON.stringify(body);
  }

  const response = await fetch(process.env.GATSBY_API_BASE_URL + endpoint, params);

  if(!response.ok) {
    const json = await response.json();
    throw new Error(json.message);
  }

  return response.json();
}