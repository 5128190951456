import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Helmet } from 'react-helmet';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
// import MoreVert from "@material-ui/icons/MoreVert";
// import ViewList from "@material-ui/icons/ViewList";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button";

import headerStyle from "./styles/components/headerStyles";

const pages = [
  {
    path: '/billing',
    name: 'Billing'
  },
  {
    path: '/contacts',
    name: 'Contacts'
  },
  {
    path: '/profile',
    name: 'Profile'
  },
  {
    path: '/email-marketing',
    name: 'Email Marketing'
  },
  {
    path: '/settings',
    name: 'Settings'
  },
  {
    path: '/open-escrows/purchase',
    name: 'Open Escrows - Purchase'
  },
  {
    path: '/open-escrows/refinance',
    name: 'Open Escrows - Refinance'
  }
]

function Header({ ...props }) {
  // function makeBrand() {
  //   var name;
  //   props.routes.map((prop, key) => {
  //     if (prop.collapse) {
  //       prop.views.map((prop, key) => {
  //         if (prop.path === props.location.pathname) {
  //           name = prop.name;
  //         }
  //         return null;
  //       });
  //     }
  //     if (prop.path === props.location.pathname) {
  //       name = prop.name;
  //     }
  //     return null;
  //   });
  //   return name;
  // }
  const { classes, color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  // const sidebarMinimize =
  //   classes.sidebarMinimize +
  //   " " +
  //   cx({
  //     [classes.sidebarMinimizeRTL]: rtlActive
  //   });

  const page = pages.find(({ path }) => props.location.pathname.indexOf(path) > -1);
  const pageTitle = page ? page.name : 'Not Found';

  return (
    <AppBar position="fixed" className={classes.appBar + appBarClasses}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Toolbar className={classes.container}>
        {/* <Hidden smDown>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden> */}
        <div className={classes.flex}>
          <div className={classes.title} color="transparent">
            {/* {props.title} */}
            {pageTitle}
          </div>
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks/>
        </Hidden>
        <Hidden mdUp>
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
