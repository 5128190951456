import React, { useEffect, useState } from "react";

import * as hostedFields from 'braintree-web/hosted-fields';

// import { Formik, Form } from 'formik';
// import { string, object, shape } from 'yup';

// import EnhancedField from 'siteComponents/EnhancedField';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

import styles from './CreditCardForm.module.scss';

function ErrorLabel({ name, state, submitCount, message }) {
  if(state && !state.fields[name].isValid && submitCount) {
    return (
      <FormHelperText error>{message}</FormHelperText>
    );
  }

  return null;
}

export default ({
  client,
  customer,
  onSuccess = null,
  setCustomer,
  setIsEditing,
  subscription
}) => {
  const { user } = useFirebase();
  const { completeAction } = useSnackbar();

  const [instance, setInstance] = useState(null);
  const [state, setState] = useState(null);
  const [submitCount, setSubmitCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(user.displayName || '');

  function getSubmitFunction(instance) {
    return async function handleSubmit(e) {
      setSubmitCount(count => count + 1);
  
      if(typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
  
      const currentState = instance.getState();
  
      if(name.length < 1) return;
  
      if(['number', 'expirationDate', 'cvv', 'postalCode'].some(name => !currentState.fields[name].isValid)) return;
  
      // everything is valid - proceed with verifying the card
      setIsSubmitting(true);
      
      await completeAction(async() => {
        const { nonce } = await instance.tokenize();
        
        let message;

        if(!customer) {

          // create a new one
          const response = await sendApiRequest(
            '/users/billing/customer',
            user,
            'post',
            { name, nonce }
          );

          setCustomer(response.customer);

          message = 'Payment method successfully added!';

        } else if (customer.paymentMethods.length) {

          // swap out the payment method
          const currentMethod = customer.paymentMethods[0];

          const response = await sendApiRequest(
            '/users/billing/paymentMethod',
            user,
            'put',
            {
              name,
              nonce,
              token: currentMethod.token,
              subscriptionId: subscription ? subscription.id : null,
              to: 'cc',
              from: currentMethod.imageUrl.indexOf('paypal') >= 0 ? 'paypal' : 'cc'
            }
          );
  
          setCustomer({
            ...customer,
            paymentMethods: [response.paymentMethod]
          });

          message = 'Payment method successfully updated!';

        } else {

          // customer has no payment methods currently; add one

          const response = await sendApiRequest(
            '/users/billing/paymentMethod',
            user,
            'post',
            {
              name,
              nonce
            }
          );

          setCustomer({
            ...customer,
            paymentMethods: [response.paymentMethod]
          });

          message = 'Payment method successfully added!';

        }
        
        return message;
      }, () => {
        setIsEditing(false);
        if(typeof onSuccess === 'function') {
          onSuccess();
        }
      });
      
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function load() {
      try {

        const options = {
          client,
          styles: {
            'input': {
              'font-size': '16px',
              'font-family': 'courier, monospace',
              'font-weight': 'lighter',
              'color': '#ccc'
            },
            ':focus': {
              'color': 'black'
            },
            '.valid': {
              'color': '#8bdda8'
            }
          },
          fields: {
            number: {
              selector: '#card-number',
              // placeholder: '4111 1111 1111 1111'
            },
            cvv: {
              selector: '#cvv',
              // placeholder: '123'
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YY'
            },
            postalCode: {
              selector: '#postal-code',
              // placeholder: '11111'
            }
          }
        }
  
        const instance = await hostedFields.create(options);
  
        setInstance(instance);
        setState(instance.getState());

        instance.on('validityChange', () => setState(instance.getState()));
        instance.on('cardTypeChange', () => setState(instance.getState()));
        instance.on('inputSubmitRequest', getSubmitFunction(instance));

        return instance;

      } catch(e) {
        console.log(e)
      }
    }

    const inst = load();
    return () => Promise
      .resolve(inst)
      .then(fin => fin.teardown());
  }, [])

  return (
    <>
      {instance === null && <CircularProgress/>}
      <Grid container>
        <Grid item md={6}>
          <form onSubmit={getSubmitFunction(instance)}>
            <div className={instance === null ? styles.hidden : null}>
              <div className={styles.field}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="name"
                    label="Cardholder Name"
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                  {!name.length && submitCount > 0 && (
                    <FormHelperText error>Please enter the cardholder name.</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className={styles.field}>
                <InputLabel>Card Number</InputLabel>
                <div className={styles.input} id="card-number"/>
                <ErrorLabel
                  name="number"
                  state={state}
                  submitCount={submitCount}
                  message="Please correct the card number."
                />
              </div>
              <div className={styles.field}>
                <InputLabel>Expiration Date</InputLabel>
                <div className={styles.input} id="expiration-date"/>
                <ErrorLabel
                  name="expirationDate"
                  state={state}
                  submitCount={submitCount}
                  message="Please correct the expiration date."
                />
              </div>
              <div className={styles.field}>
                <InputLabel>CVV</InputLabel>
                <div className={styles.input} id="cvv" />
                <ErrorLabel
                  name="cvv"
                  state={state}
                  submitCount={submitCount}
                  message="Please correct the security code."
                />
              </div>
              <div className={styles.field}>
                <InputLabel>Zip Code</InputLabel>
                <div className={styles.input} id="postal-code"/>
                <ErrorLabel
                  name="cvv"
                  state={state}
                  submitCount={submitCount}
                  message="Please correct the zip code."
                />
              </div>
              {isSubmitting ? (
                <CircularProgress/>
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                  {customer !== null && customer.paymentMethods.length > 0 && (
                    <>
                      {' '}
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}