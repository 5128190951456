import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import CreditCardForm from './CreditCardForm';
import PaypalForm from './PaypalForm';

import { useFirebase, useSnackbar } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

import styles from './PaymentMethod.module.css';

export default ({
  client,
  customer,
  setCustomer,
  onSuccess = null,
  subscription
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { user } = useFirebase();
  const { completeAction } = useSnackbar();

  async function handleDelete() {
    setIsDeleting(true);

    await completeAction(async() => {
      const response = await sendApiRequest(
        '/users/billing/paymentMethod',
        user,
        'delete',
        { token: customer.paymentMethods[0].token }
      );

      if(!response.success) throw new Error('Something went wrong. Please retry your request.');

      setCustomer({
        ...customer,
        paymentMethods: []
      });

      return 'Payment method successfully deleted.';
    });

    setIsDeleting(false);
  }
  
  if(isEditing || !customer || !customer.paymentMethods.length) {
    // show the credit card form
    return (
      <>
        <PaypalForm
          customer={customer}
          setCustomer={setCustomer}
          client={client}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onSuccess={onSuccess}
          subscription={subscription}
        />
        <div className={styles.divider}>OR</div>
        <CreditCardForm
          customer={customer}
          setCustomer={setCustomer}
          client={client}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onSuccess={onSuccess}
          subscription={subscription}
        />
      </>
    );
  }

  const method = customer.paymentMethods[0];

  return (
    <>
      <Typography variant="h3">Payment Method</Typography>
      {method.imageUrl.indexOf('paypal') >= 0 ? (
        <>
          <p>Account Email: <b>{method.email}</b></p>
          <div className={styles.paypalImage}>
            <img src={method.imageUrl} alt="card logo"/>
          </div>
        </>
      ) : (
        <>
          <p>Cardholder Name: {method.cardholderName}</p>
          <p>Card Type: {method.cardType}</p>
          <p>Number: {method.maskedNumber} <img src={method.imageUrl} alt="card logo"/></p>
          <p>Expiration: {method.expirationDate}</p>
          {typeof method.billingAddress !== 'undefined' && (
            <p>Zip Code: {method.billingAddress.postalCode}</p>
          )}
        </>
      )}
      {isDeleting ? (
        <CircularProgress/>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
          >
            Update/Replace
          </Button>{' '}
          {!subscription && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
        </>
      )}
    </>
  );
}