import React, { useContext, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Mail from '@material-ui/icons/Mail';
import Delete from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { Link, Location, navigate } from '@reach/router';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

import Drilldown from 'appComponents/Drilldown';
import DotMenu from 'appComponents/DotMenu';
import SendForm from 'appComponents/SendForm';

import refinanceStyles from './Refinance.module.css';
import drilldownItemStyles from 'appComponents/DrilldownItem.module.scss';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { ModalContext } from 'contexts/ModalContext';
import { SnackbarContext } from 'contexts/SnackbarContext';

const mapDocs = docs =>
  docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

export default ({ children }) => {
  const [isDrilldownOpen, setIsDrilldownOpen] = useState(false);
  const [openEscrows, setOpenEscrows] = useState(null);
  const { user, db } = useContext(FirebaseContext);
  const { openModal } = useContext(ModalContext);
  const { openSnackbar } = useContext(SnackbarContext);
  
  function updateRecords(mapped) {
    setOpenEscrows(mapped);
  }

  useEffect(() => {
    const snapshot = db
      .collection('users')
      .doc(user.uid)
      .collection('refinanceOpenEscrows')
      .orderBy('updatedAt', 'desc');
    
    const unsubscribe = snapshot.onSnapshot(snap => updateRecords(mapDocs(snap.docs)));

    return unsubscribe;
  }, []);

  function shareRecord(id) {
    openModal('Sent to up to 5 recipients', (
      <SendForm endpoint={`/users/open-escrows/refinance/${id}/email`}/>
    ));
  }

  function deleteRecord(id) {
    const confirmed = window.confirm('Delete this record?');

    if (confirmed) {
      // delete it from Firestore
      db
        .collection('users')
        .doc(user.uid)
        .collection('refinanceOpenEscrows')
        .doc(id)
        .delete()
        .then(() => {
          openSnackbar('Record successfully deleted.', 'success');
          if(window.location.pathname.indexOf(id) > -1) {
            navigate('/app/open-escrows/refinance');
          }
        })
        .catch(e => openSnackbar(e.message, 'error'));
    }
  }

  function openDrilldown(e) {
    e.stopPropagation();
    setIsDrilldownOpen(true);
  };

  function closeDrilldown() {
    setIsDrilldownOpen(false);
  };

  if(openEscrows === null) {
    return <CircularProgress />;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start'
      }}
    >
      <Drilldown
        items={openEscrows}
        type="Open Escrow"
        path="/app/open-escrows/refinance"
        open={isDrilldownOpen}
        closeDrilldown={closeDrilldown}
        render={({ id, index, path, property, updatedAt }) => (
          <Location key={id}>
            {({ location }) => (
              <ListItem
                className={[
                  drilldownItemStyles.item,
                  (index === 0 &&
                    location.pathname === '/app/open-escrows/refinance') ||
                  location.pathname === path
                    ? drilldownItemStyles.active
                    : ''
                ]
                  .filter(Boolean)
                  .join(' ')}
                button
              >
                <Link to={path}>
                  <ListItemText
                    primary={property.address}
                    secondary={
                      property.city +
                      ', ' +
                      property.state +
                      ' ' +
                      property.zip
                    }
                  />
                  <Typography variant="caption">
                    Updated: {new Date(updatedAt).toLocaleString()}
                  </Typography>
                </Link>
                <DotMenu
                  options={[
                    {
                      text: 'Email',
                      icon: Mail,
                      callback: () => shareRecord(id)
                    },
                    {
                      text: 'Delete',
                      icon: Delete,
                      callback: () => deleteRecord(id)
                    }
                  ]}
                />
              </ListItem>
            )}
          </Location>
        )}
      />
      <div
        style={{
          marginLeft: '20px',
          maxHeight: 'calc(100vh - 132px)',
          overflowY: 'auto'
        }}
      >
        <Hidden mdUp>
          <div className={refinanceStyles.browseButton}>
            <Button
              variant="contained"
              color="secondary"
              onClick={openDrilldown}
            >
              Browse Records
            </Button>
          </div>
        </Hidden>
        {children}
      </div>
    </div>
  );
}