import React from 'react';

import { navigate } from '@reach/router';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import TemplateForm from './TemplateForm';
import Schedule from './Schedule';

import styles from './styles.module.css';

export default ({ crons, hasActiveSubscription, templates, setCrons }) => (
  <>
    <Typography variant="h3" className={styles.heading}>
      <span className={styles.number}>1</span>
      Compose Your Message
    </Typography>
    <TemplateForm template={templates.length ? templates[0] : null}/>
    <Typography variant="h3" className={styles.heading}>
      <span className={styles.number}>2</span>
      Schedule Your Mailing(s)
    </Typography>
    {hasActiveSubscription ? (
      <Schedule
        schedule={crons}
        setCrons={setCrons}
      />
    ) : (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate('/app/billing')}
      >
        Please enable billing to use this feature.
      </Button>
    )}
  </>
)