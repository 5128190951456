import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import { string, object } from 'yup';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import EnhancedField from 'siteComponents/EnhancedField';

import { useFirebase } from 'utilities/hooks';
import { sendApiRequest } from 'utilities/api';

import styles from './TestMessageForm.module.css';

const validationSchema = object().shape({
  firstName: string(),
  lastName: string(),
  email: string()
    .email('Please enter a valid email address.')
    .required('Please enter an email address.')
});

const inputs = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text'
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text'
  },
  {
    name: 'email',
    label: 'Email Address',
    type: 'email'
  }
];

export default () => {
  const { user } = useFirebase();

  const [resultStyle, setResultStyle] = useState(null);
  const [resultMessage, setResultMessage] = useState(null);

  const initialValues = {
    email: user.email,
    firstName: '',
    lastName: ''
  };

  if(user.displayName) {
    const split = user.displayName.lastIndexOf(' ');

    if(split > -1) {
      initialValues.firstName = user.displayName.slice(0, split);
      initialValues.lastName = user.displayName.slice(split + 1);
    } else {
      initialValues.firstName = user.displayName;
    }
  }

  async function handleSubmit(values, actions) {
    setResultMessage(null);

    try {

      const response = await sendApiRequest(
        '/users/templates/test',
        user,
        'post',
        values
      );

      if(response.results.total_accepted_recipients !== 1) {
        throw new Error('Something went wrong. Please try your request again.');
      }

      setResultStyle(styles.success);
      setResultMessage('Message successfully sent!');

    } catch(e) {

      setResultStyle(styles.error);
      setResultMessage(e.message);

    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => (
        <Form>
          <div className={styles.formBody}>
            {inputs.map(input => (
              <EnhancedField key={input.name} {...input} fullWidth/>
            ))}
          </div>
          {isSubmitting ? (
            <CircularProgress/>
          ) : (
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Send
            </Button>
          )}
          {resultMessage !== null && (
            <div className={resultStyle}>{resultMessage}</div>
          )}
        </Form>
      )}
    />
  );
}