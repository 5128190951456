import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { auth as firebaseAuth } from 'firebase/app';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutlined from "@material-ui/icons/LockOutlined";

// core components
import Button from "components/CustomButtons/Button";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import EnhancedField from 'siteComponents/EnhancedField';

import { FirebaseContext } from 'contexts/FirebaseContext';
import { SnackbarContext } from 'contexts/SnackbarContext';

import styles from './Login.module.css';

const validationSchema = object().shape({
  email: string()
    .email('Please enter a valid email address.')
    .required('Please enter your email address.'),
  password: string()
    .required('Please enter your password.')
    .min(6, 'Your password must be at least 6 characters.')
});

export default function LoginForm({ classes, setShowForgotPassword }) {
  const { auth } = useContext(FirebaseContext);
  const { openSnackbar } = useContext(SnackbarContext);

  async function handleSubmit({ email, password }, actions) {

    try {

      await auth.signInWithEmailAndPassword(email, password);

    } catch(e) {

      openSnackbar(e.message, 'error');

    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => (
        <>
          <Form>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Log In</h4>
              <div className={classes.socialLine}>
                {[
                  {
                    name: 'Facebook',
                    icon: 'fab fa-facebook-square',
                    provider: new firebaseAuth.FacebookAuthProvider()
                  },
                  // {
                  //   name: 'Twitter',
                  //   icon: 'fab fa-twitter',
                  //   provider: new firebaseAuth.TwitterAuthProvider()
                  // },
                  {
                    name: 'Google',
                    icon: 'fab fa-google',
                    provider: new firebaseAuth.GoogleAuthProvider()
                  }
                ].map(({ name, icon, provider }) => {
                  return (
                    <Button
                      color="transparent"
                      justIcon
                      key={name}
                      className={classes.customButtonClass}
                      onClick={() => auth.signInWithRedirect(provider)}
                    >
                      <i className={icon} />
                    </Button>
                  );
                })}
              </div>
            </CardHeader>
            <CardBody>
              <EnhancedField
                name="email"
                label="Email"
                fullWidth
                style={{ width: '300px' }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  inputProps: {'data-test': 'email'}
                }}
              />
              <EnhancedField
                name="password"
                label="Password"
                fullWidth
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlined className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  type: 'password',
                  inputProps: {'data-test': 'password'}
                  // 'data-test': 'password'
                }}
              />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              {/* TODO: <Modal actions content> */}
              {isSubmitting ? (
                <CircularProgress/>
                ) : (
                  <Button type="submit" color="rose" simple size="lg" block>
                    Let's Go
                  </Button>
                )
              }
            </CardFooter>
          </Form>
          <div className={styles.forgotContainer}>
            <span className={styles.forgotLink} onClick={() => setShowForgotPassword(true)}>
              Forgot password?
            </span>
          </div>
        </>
      )}
    />
  )
}