import React from 'react';

import styles from './UndoDelete.module.css';

export default ({ db, userId, id, data }) => {
  function handleClick() {
    db
      .collection('users')
      .doc(userId)
      .collection('contacts')
      .doc(id)
      .set(data);
  }

  return (
    <span className={styles.undo} onClick={handleClick}>Undo</span>
  );
}