import React from "react";
import PropTypes from "prop-types";
import { Redirect } from '@reach/router';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AuthHeader from "dashboard-pro-child/AuthHeader";
import Footer from "dashboard-pro-child/Footer";

import authStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle";

import bgImage from 'localAssets/images/auth-bg-big.jpg';

import styles from './Auth.module.scss';

const Auth = ({ children, classes, user, ...rest }) => {
  if(user && user.emailVerified) {
    try {
      const returnUrl = localStorage.getItem('returnUrl');
      if(!returnUrl) throw new Error('no saved return URL');
      localStorage.removeItem('returnUrl');

      return (
        <Redirect to={returnUrl} noThrow/>
      );
    } catch(e) {
      return (
        <Redirect to="/app/profile" noThrow/>
      );
    }
  }

  return (
    <div
      className={styles.wrap}
      style={{
        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("
          + bgImage + ")"
    }}>
      <AuthHeader {...rest} />
      {children}
      <Footer white />
    </div>
  );
}

Auth.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(authStyle)(Auth);