import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';

import { ModalContext } from '../../contexts/ModalContext';
import SendForm from 'appComponents/SendForm';

export default () => {
  const { openModal } = useContext(ModalContext);

  function launch() {
    openModal('Send Up to 5 People', <SendForm/>);
  }

  return (
    <Button onClick={launch}>Click Me</Button>
  )
}