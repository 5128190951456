import React, { useContext, useState } from 'react';

import RolesModal from 'appComponents/RolesModal';
import OpenEscrowForm from '../OpenEscrowForm';

import { FirebaseContext } from 'contexts/FirebaseContext';

import inputs from './refinance-inputs';

const roles = [
  {
    name: 'lender',
    label: 'Lender'
  },
  {
    name: 'transactionCoordinator',
    label: 'Transaction Coordinator'
  }
];

export default () => {
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [profile, setProfile] = useState(null);
  const { db, user } = useContext(FirebaseContext);

  async function liftRoles(roles) {
    if(Object.keys(roles).some(key => roles[key])) {
      // get the profile info
      const doc = await db
        .collection('users')
        .doc(user.uid)
        .get();
      
      if(doc.exists) {
        setProfile(doc.data());
      } else {
        setProfile({});
      }
    }

    setSelectedRoles(roles);
  }

  if(selectedRoles && profile) {
    return (
      <OpenEscrowForm
        profile={profile}
        inputs={inputs}
        selectedRoles={selectedRoles}
        type="refinance"
      />
    );
  }

  return (<RolesModal roles={roles} liftRoles={liftRoles}/>);
}