import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import BulkAction from './BulkAction';
import Contact from './Contact';
import PaginationActions from './PaginationActions';

import styles from './common.module.css';

export default ({ contacts }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  
  function handleChangePage(page) {
    setPage(page);
  }

  function handleChangeRowsPerPage(e) {
    setPage(0);
    setRowsPerPage(+e.target.value);
  }

  function handleSelectedAllClick(e) {
    if(e.target.checked) {
      return setSelected(contacts.map(({ id }) => id));
    }
    setSelected([]);
  }

  function handleChange(id) {
    const index = selected.indexOf(id);

    if(index > -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, id]);
    }
  }

  return (
    <Paper className={styles.container}>
      <Toolbar>
        {selected.length ? (
          <Grid container justify="space-between">
            <Typography color="inherit" variant="subtitle1">
              {selected.length} selected
            </Typography>
            <div>
              <BulkAction
                color="primary"
                action="enable"
                selected={selected}
              >
                Include
              </BulkAction>{' '}
              <BulkAction
                color="secondary"
                action="disable"
                selected={selected}
              >
                Don't Include
              </BulkAction>{' '}
              <BulkAction
                color="secondary"
                action="delete"
                selected={selected}
              >Delete</BulkAction>
            </div>
          </Grid>
        ) : (
          <Typography variant="h6">
            Contacts
          </Typography>
        )}
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow
            hover
            aria-checked={false}
          >
            <TableCell>
              <Checkbox
                indeterminate={false}
                checked={contacts.length === selected.length}
                onClick={handleSelectedAllClick}
              />
            </TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Receives Emails</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(contact => {
            const { id } = contact;

            return (
              <Contact
                key={id}
                id={id}
                data={contact.data()}
                handleChange={handleChange}
                isSelected={selected.indexOf(id) > -1}
              />
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={4}
            count={contacts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}